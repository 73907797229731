.d-block {
    display: block !important;
}

.p-0 {
    padding: 0 !important;
}

.m-16 {
    margin: 16px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.ml-8 {
    margin-left: 8px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-16 {
    margin-top: 16px !important;
}

.bold {
    font-weight: 500 !important;
}

.rounded {
    border-radius: 50% !important;
}

.flex {
    display: flex;
}

.flex-auto {
    flex: 1 1 auto !important;
}

.no-height {
    height: auto !important;
}

.avatar {
    display: flex;
}

.avartar-image {
    margin-right: 8px;
}

.avartar-name {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #202020;
}

.account-id {
    composes: ml-8 mr-8;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.1px;
    color: #AFAFAF;
    margin: 0 8px;
}

.avartar-time {
    font-weight: 400;
    font-size: 8px;
    line-height: 9px;
    letter-spacing: 0.25px;
    color: #AFAFAF;
}

.content {
    color: #202020;
    padding: 16px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: pre-wrap;
}

.tool {
    border-top: 1px solid #F1F3F4;
    color: #AFAFAF;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.banner {
    width: 100%;
    /* height: 250px; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.list-user {
    display: flex;
    margin-top: 16px;
}

.avartar-image-mini {
    composes: rounded;
    width: 16px;
    height: 16px;
    object-fit: cover;
}

.img-instagram {
    width: 100%;
    height: 200px;
    object-fit: contain;
    padding: 12px;
    border: 1px solid var(--main-line);
    border-radius: 4px;
}

.iframe {
    width: 100%;
    min-height: 200px;
}

.embed-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6D6D6D;
    width: 100%;
}

.copy-link {
    font-weight: normal;
    color: #1C00C2;
    cursor: pointer;
}

.box-statistics-publisher {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    flex: 1 1 auto;
    padding: 16px;
    width: 100%;
}

.box-statistics-publisher-2 {
    display: flex;
}

.box-content-publisher-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #000000;
}

.box-content-publisher-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    display: flex;
}

.box-content-publisher-number {
    display: flex;
    color: #41B983;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.box-statistics-publisher-img {
    width: 110px;
    display: flex;
    justify-content: center;
}

.box-statistics-publisher-img img {
    width: 64px;
    height: 64px;
}

.box-statistics-publisher-detail {
    width: calc(100% - 130px);
}

.box-statistics-publisher-detail-title {
    color: #202020;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.box-statistics-publisher-detail-amount {
    color: #41B983;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
}

.box-statistics-publisher-detail-purchase {
    display: flex;
    justify-content: space-between;
}

.box-statistics-publisher-detail-purchase-title {
    color: #6D6D6D;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.box-statistics-publisher-detail-purchase-amount {
    color: #202020;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.box-title {
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    padding: 16px;
    border-bottom: 1px solid #F1F3F4;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.img-campaign {
    width: 100px;
    border-radius: 2px;
}

.item-campaign {
    padding: 16px 0;
    border-bottom: 1px solid #F1F3F4;
}

.title {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
    text-transform: uppercase;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.box-campaign {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    width: 20%;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    justify-content: center;
}

.default-align {
    align-items: center;
    justify-content: space-between;
}

.box-embed-code {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 12px;
}

.topbar-account {
    justify-content: end;
    min-width: auto !important;
    /* margin-right: 20px; */
}

.r-10 {
    right: 10px !important;
    margin-top: 7px !important;
}

.coupon-payment {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 10px;
    align-items: end;
}

.coupon-payment-commission {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
    align-items: end;
}

.comment-coupon-special {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.group-coupon-code {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.badge-secondary {
    background: #ccc;
    border-radius: 4px;
    padding: 2px 5px;
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
}

.group-flex-coupon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
}

.icon-upload-1 {
    min-width: 0 !important;
    position: absolute;
    top: 14px;
    left: 40px;
    width: 32px;
    height: 32px;
    background: #1C00C2;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 15%);
    border-radius: 50%;
    color: #fff;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.icon-upload-2 {
    min-width: 0 !important;
    position: absolute;
    top: 14px;
    right: 40px;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    color: #6D6D6D;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.icon-upload-3 {
    color: #1C00C2;
    cursor: pointer;
    margin-left: 12px;
}

.img-fit {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.warrning-account-active {
    background: #E89D35;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.07);
    border-radius: 2px;
    height: 44px;
    padding: 0 15px;
    margin-top: 20px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: left;
}

.warning-account-active-a {
    color: #FFFFFF;
    text-decoration: underline;
}

.text-box-1 {
    height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #6D6D6D;
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border {
    border: 1px solid var(--main-line);
}

.campaign-publisher-list {
    display: flex;
    gap: 1rem;
}

.box-statistics-publisher-desc-membership-class span {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #566577;
}

.box-statistics-publisher-title-membership-class {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #1D5CD3;
}