@media screen and (max-width: 640px) {

    html,
    body,
    input {
        font-size: 13px !important;
        margin: 0;
        padding: 0;
    }

    .ladiui.aside.pc {
        display: none !important;
    }

    .ladiui.aside.mobile {
        display: block !important;
    }

    .ladiui.menu-mobile {
        display: flex;
        width: 100vw;
        position: fixed;
        bottom: 0;
        min-height: 60px;
        border: 1px solid var(--main-line);
        z-index: 1;
        background-color: var(--main-white);
    }

    .ladiui.menu-item {
        padding: 0;
        width: calc(100%/6) !important;
        padding: 0;
        align-items: center;
        justify-content: center;
        display: flex;
        transition: width .3s;
        border-left: 2px solid var(--secondary-color);
    }

    .ladiui.menu-item.active {
        background-color: var(--main-white);
        border-bottom: 2px solid var(--main-primary);
        border-left: none;
    }

    .ladiui.menu-item {
        transition: width .3s;
        width: auto;
        border-left: none;
    }

    .ladiui.main:not(.table-vertical) {
        margin: 0;
        width: 100%;
        overflow: scroll;
        height: calc(100vh - 70px);
    }

    .ladiui.topbar {
        display: none;
    }

    .ladiui.content-main-full {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0;
    }

    .ladiui.wrapper {
        min-width: 0;
    }

    .btn-calendar {
        display: none !important;
    }

    /* .btn-calendar.sevendaysago {
        display: block !important;
    } */

    .filter-calendar-dashboard-publisher {
        justify-content: space-between;
        margin-top: 0 !important;
    }

    .dashboard-statistics-publisher {
        display: grid !important;
        grid-template-columns: repeat(2, 1fr);
        gap: 12px !important;
        overflow: hidden;
    }

    .dashboard-statistics-publisher>div {
        height: 100px;
        padding: 10px 16px;
    }

    .dashboard-publisher {
        display: block !important;
    }

    .dashboard-right-publisher {
        margin-top: 16px;
        width: 100%;
    }

    .publisher-container {
        display: block;
    }

    .box-publisher {
        display: block;
        width: auto;
    }

    .box-publisher .box-logo {
        position: relative;
        top: auto;
        margin-top: 24px;
    }

    .box-publisher .box-publisher-left,
    .box-publisher .box-publisher-right {
        padding: 24px;
        padding-bottom: 0;
    }

    .publisher-container .box-publisher .box-title {
        margin-bottom: 24px;
    }

    .publisher-container .box-publisher .box-description {
        margin-top: 0;
    }

    .publisher-container .box-publisher .box-publisher-right .box-title {
        margin-bottom: 0;
    }

    .affiliate-account-box {
        width: auto;
    }

    .dashboard-left-publisher {
        width: 100%;
    }

    .DateInput_input__small {
        font-size: 14px !important;
    }

    .DateInput__small {
        /* width: 87px !important; */
    }

    .search-action .showing {
        display: none;
    }

    .ladiui.search-width {
        min-width: 0;
    }

    .ladiui.content-main-tool.align-item-baseline {
        display: block;
    }

    .ladiui.btn-group.custom-btn-list-landingpage {
        margin-left: 0;
    }

    .campaign-publisher-list {
        grid-template-columns: repeat(1, minmax(100%, 1fr));
        gap: 12px !important;
    }

    .get-short-link {
        right: 0px;
        top: -25px;
    }

    .list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-img {
        width: 100px;
        height: 100px;
    }

    .list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded {
        width: calc(100% - 110px);
    }

    .list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded textarea {
        height: 83px;
    }

    .input-base-link {
        padding-right: 12px !important;
    }

    .img-bg-campaign {
        height: 150px;
    }

    .link-share-campaign span {
        display: none;
    }


    .summary-box {
        grid-template-columns: repeat(2, 1fr);
    }

    .content-main-title.dashboard-publisher>div {
        display: block;
    }

    .header-main-report {
        display: block !important;
    }

    .header-main-report .main-title {
        padding-bottom: 20px;
    }

    .header-main-report .calendar-search {
        margin-left: 0;
    }

    .content-main-report {
        padding-bottom: 0 !important;
    }

    .content-main-tool-2 {
        position: relative;
        /* top: 66px; */
        /* right: 10px; */
        margin-top: 14px !important;
    }

    .content-main-tool-2 .filter-condtion-area {
        margin-right: 0 !important;
        width: 100%;
    }

    .list-filter-condition {
        right: 0;
    }

    .list-filter-condition.show {
        width: calc(100vw - 27px);
        z-index: 2;
    }

    .content-condition-filter {
        flex-wrap: wrap;
    }

    .content-condition-filter>div,
    .content-condition-filter div.btn-group,
    .content-condition-filter div.dropdown {
        width: 100%;
        margin-right: 0 !important;
        padding: 0 !important;
    }

    .content-condition-filter input[type='text'] {
        width: 100% !important;
    }

    .content-condition-filter>div {
        margin-bottom: 10px;
    }

    .content-condition-filter>div:first-child {
        margin-top: 10px;
    }

    .content-condition-filter .ladiui.select-search .btn {
        max-width: 100%;
    }

    .result-filter-condition {
        position: absolute;
        /* z-index: 1; */
        right: 0;
        width: calc(100vw - 27px);
        overflow: auto;
        background: #fff;
        padding-bottom: 8px;
    }

    li.ladiui.nav-item.more-item {
        width: 100%;
        padding-bottom: 10px;
        margin: 0;
        border-left: 1px solid var(--main-line);
        border-right: 1px solid var(--main-line);
        padding-left: 10px;
        padding-right: 10px;
        display: none;
    }

    li.ladiui.nav-item.more-item-first {
        padding-top: 10px;
        margin-top: 10px;
        border-top: 1px solid var(--main-line);
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    li.ladiui.nav-item.more-item-last {
        border-bottom: 1px solid var(--main-line);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .more-click-on {
        border-bottom: 0 !important;
    }

    .more-click-on li.ladiui.nav-item.more-item {
        display: block;
    }

    .more-click {
        display: block;
    }

    .more-click.hidden {
        display: none;
    }

    .btn-export-invoice,
    .btn-request-invoice {
        display: none !important;
    }

    .ladiui.search-group {
        width: 100%;
    }

    .main-filter-condition {
        width: 100%;
    }

    .main-search-condition {
        position: absolute;
        top: 0;
        right: 0;
    }

    .DateRangePicker_picker__directionLeft {
        width: 100%;
        overflow-x: auto;
        border: 1px solid #dbdbdb;
        /* z-index: 3; */
    }

    .DateInput_1 .DateInput_fang {
        top: 39px !important;
    }

    .more-item-menu {
        display: none !important;
    }

    .menu-item-more {
        position: relative;
    }

    .menu-item-more .sub-menu {
        position: absolute;
        bottom: 59px;
        z-index: 2;
        background: #fff;
        border: 1px solid var(--main-line);
        right: -2px;
        width: 200px;
        border-bottom: 0px;
        display: none;
    }

    .menu-item-more:hover .sub-menu {
        display: block;
    }

    .menu-item-more .sub-menu .sub-menu-item {
        padding: 6px 12px;
        border-bottom: 1px solid var(--main-line);
    }

    .menu-item-more .sub-menu .sub-menu-item:last-child {
        border-bottom: 0px;
    }

    .menu-item-more .sub-menu .sub-menu-item .ladiui.menu-text {
        overflow: unset;
        opacity: 1;
        color: var(--main-secondary);
    }

    .ladiui.menu-item:hover i {
        background-color: var(--main-secondary);
    }

    .ladiui.modal .modal-dialog {
        width: 95%;
        min-width: 0;
    }

    .content-detail-commission-value,
    #modal-detail-invoice .modal-body .detail-invoice,
    #modal-detail-invoice-request .modal-body .detail-invoice {
        display: block;
    }

    .content-detail-commission-value .detail-total-commission,
    .detail-invoice-total-content {
        margin-top: 24px;
    }

    .main-title-dashboard {
        display: block;
    }

    .ladiui.nav.commission-tabs,
    .ladiui.nav.tab-detail-commission {
        flex-wrap: nowrap;
        overflow-x: auto;
    }

    .commission-tabs .nav-item span {
        white-space: nowrap;
    }

    td .bold-500 {
        white-space: nowrap;
    }

    table.ladiui.table th {
        padding: 0 6px;
    }

    table.ladiui.table td {
        padding-left: 6px;
        padding-right: 6px;
    }

    /* table.ladiui.table th.col-first {
        padding: 0;
    } */

    #modal-detail-commission .flex-item-detail-commission,
    .info-invoice {
        display: block;
    }

    .info-invoice>div {
        margin-top: 24px;
    }

    .no-grid-mobile {
        display: block !important;
    }

    .mobile-table-scroll {
        overflow: auto;
        min-height: 250px;
    }
}