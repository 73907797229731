* {
    box-sizing: border-box;
    margin: 0 auto;
}

.ladiui.aside.mobile {
    display: none !important;
}

.ladiui.wrapper {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    min-width: 1366px;
    height: 0;
}

.ladiui.topbar {
    height: 64px;
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--main-white);
    position: fixed;
    width: 100%;
    z-index: 10;
    top: var(--notification-height);
}

.ladiui.main {
    position: relative;
    margin-left: 74px;
    width: calc(100% - 74px);
}

.ladiui.topbar-menu {
    position: fixed;
    padding: 19px 0px 19px 24px;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: var(--main-secondary);
}

.ladiui.topbar-menu-right {
    position: fixed;
    right: 20px;
}

.ladiui.btn-group {
    padding: 14px 0px 14px 0px;
}

.ladiui.app-tools img {
    pointer-events: none;
}

.ladiui.tool-notificiations {
    padding: 19px 12px 19px 24px;
}

.ladiui.noti.dropdown-menu {
    /* left: 30px; */
    position: absolute;
    width: 400px;
    right: 122px;
    margin-top: 15px
}

.ladiui.noti-content {
    display: flex;
    padding: 16px;

}

.ladiui.noti-content.active {
    background-color: rgba(24, 82, 250, .05) !important;
}

.ladiui.noti-content:hover {
    background-color: var(--main-line);
}

.ladiui.noti-header {
    padding: 6px 16px 18px;
    display: flex;
    justify-content: space-between;
}

.ladiui.noti-header-right {
    color: var(--main-primary);
}

.ladiui.noti-header-right i {
    margin-right: 4px;
}

.ladiui.noti-i {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    pointer-events: none;
}

.ladiui.noti-text {
    margin-left: 56px;
    cursor: pointer;
    pointer-events: none;
}

.ladiui.noti-sub {
    margin-top: 4px;
    color: var(--main-secondary);
}

.ladiui.noti-time {
    margin-top: 8px;
    color: var(--main-secondary);
}

.ladiui.tool-help {
    padding: 20px 12px 20px 12px;
}

.ladiui.tool-help .dropdown-menu {
    position: absolute;
    margin-top: 15px !important;
    right: 0;
    width: 200px;
}

.ladiui.tool-help .dropdown-item {
    display: flex;
    align-items: center;
}

.ladiui.tool-language {
    padding: 20px 12px 20px 12px;
}

.ladiui.language-img {
    cursor: pointer;
    pointer-events: none;
    color: white;
    font-size: 0;
}

.ladiui.tool-language img {
    border-radius: 20px;
}

.ladiui.tool-language .dropdown-menu {
    min-width: 126px;
    left: 70px;
    position: absolute;
    margin-top: 15px;
    /* right: 0; */
}

.ladiui.my-account {
    cursor: pointer;
}

.ladiui.my-account.dropdown-toggle::after {
    content: none;
}

.ladiui.dropdown-toggle.nav-bar {
    min-width: 200px;
}

.ladiui.my-account-avata {
    padding: 12px 0px 12px 12px;
    pointer-events: none;
}

.ladiui.my-account-info {
    padding-left: 16px;
    pointer-events: none;
}

.ladiui.my-account-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    pointer-events: none;
}

.ladiui.my-account-type {
    padding: 2px 0px 0px 0.5px;
}

.ladiui.account.dropdown-menu {
    width: 332px;
    /* left: 220px; */
    position: absolute;
    right: 0;
}

.ladiui.account .dropdown-item {
    padding: 12px 21px;
}

.ladiui.account {
    position: relative;

}


.ladiui.account-header {
    display: flex;
    justify-items: center;
    align-items: center;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.account-header-text {
    pointer-events: none;
}

.ladiui.account-content {
    padding: 14px 24px 0;
}

.ladiui.account-content-footer {
    padding: 0px 24px;
    margin-bottom: 12px;
}

.ladiui.account-footer {
    border-top: 1px solid var(--main-line);
}

.ladiui.account-avatar {
    margin-right: 16px;
    display: flex;
    position: relative;
}

.ladiui.account-avatar a {
    padding: .5px;
    border-radius: 20px;
    background-color: var(--main-white);
    position: absolute;
    top: 42px;
    left: 42px;
}

.ladiui.account-body-content {
    pointer-events: none;
}

.ladiui.account-avatar img {
    pointer-events: none;
}

.ladiui.avt {
    width: 64px;
    height: 64px;
}

.ladiui.avartar {
    pointer-events: none;
}

.ladiui.account-body-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
}

.ladiui.my-account-type i {
    margin-right: 0;
    /* position: relative; */
}

.ladiui.dropdown-item.account-body-item i {
    margin-right: 0;
}

.ladiui.account-tick {
    background-color: inherit;
    position: absolute;
    right: 24px;
}

.ladiui.account-body-item:first-child {
    margin-top: 12px;
}

.ladiui.account-body-item:last-child {
    margin-bottom: 12px;
}

.ladiui.account-body-item:hover {
    background-color: var(--main-line);
}

.ladiui.body-avatar {
    width: 48px;
    height: 48px;
    margin-right: 12px;
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;
    pointer-events: none;
}

.ladiui.body-avatar img {
    border: 2px solid rgba(0, 0, 0, 0);
    border-radius: 30px;

}

.ladiui.account-status {
    display: flex;
    justify-items: center;
    margin-top: 4px;
}

.ladiui.account-body-item.active .body-avatar {
    border: 2px solid var(--main-primary);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-tick {
    background-color: var(--main-primary);
}

.ladiui.account-body-item.active .body-avatar img {
    border: 2px solid var(--main-white);
    border-radius: 30px;
}

.ladiui.account-body-item.active .account-name {
    color: var(--main-primary);
}

.ladiui.account-duration {
    margin-left: 8px;
}

.ladiui.icon-drd {
    padding: 0px 20px 0px 8px;
}

.ladiui.content-navigation .dropdown {
    display: flex;
    flex-direction: column-reverse;
}

.ladiui.new-page.dropdown-menu {
    width: 400px;
    padding: 0;
    margin-bottom: 44px;
}

.ladiui.new-page.dropdown-item {
    display: flex;
    align-items: center;
    padding: 16px;
}

.ladiui.content-sub {
    min-width: 530px;
}

.ladiui.new-page-content {
    margin-left: 8px;
}

/*Hover*/
.ladiui.nav {
    position: fixed;
    z-index: 1000;
    top: var(--notification-height);
    left: 0;
    width: 74px;
    height: 100vh;
    background-color: var(--main-line);
    transition: width .3s;
}

.ladiui.logo-item.logo {
    padding: 16px 21px 16px 21px;
    position: relative;
    opacity: 1;
}

.ladiui.logo-item.logo-hover {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}


.ladiui.nav:hover .logo-item.logo-hover {
    position: relative;
    opacity: 1;
    transition: opacity .5s;

}

.ladiui.nav:hover .logo-item.logo {
    position: absolute;
}

.ladiui.nav:hover {
    display: block;
    width: 215px;
}

.ladiui.menu-text {
    position: absolute;
    pointer-events: none;
    left: 50px;
    opacity: 0;
    width: 0;
    overflow: hidden;
    transition: opacity .2s, width .3s;
}

@keyframes ladiLogo {
    0% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.ladiui.nav:hover .menu-text {
    opacity: 1;
    width: 100%;
}

.ladiui.nav:hover .menu-item {
    width: 215px;
}

.ladiui.menu-item {
    padding: 16px 0px 16px 23px;
    width: 74px;
    transition: width .3s;
    border-left: 2px solid var(--secondary-color);
}

.ladiui.menu-item a i {
    background-color: var(--main-secondary);
}

.ladiui.menu-item .icon-prev {
    margin-left: 16px;
}

.ladiui.menu-item:hover .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item:hover i {
    background-color: var(--main-primary);
}


.ladiui.logo-item.logo-hover {
    padding: 16px 59px 16px 21px;
}

.ladiui.menu-item.active {
    background-color: var(--main-white);
    border-left: 2px solid var(--main-primary);
}

.ladiui.menu-item.active i {
    background-color: var(--main-primary);
}

.ladiui.menu-item .icon-prev {
    transform: rotate(90deg);
}

.ladiui.menu-item.active .menu-text {
    color: var(--main-primary);
}

.ladiui.menu-item .dropdown-toggle {
    min-width: 50px;
    width: 50px;
}

.ladiui.menu-item .dropdown-toggle:focus {
    border: none;
}

.ladiui.menu-item .dropdown-toggle::after {
    content: none;
}

.ladiui.menu-item .dropdown-menu {
    padding: 0;
    box-shadow: none;
    background-color: inherit;
}

.ladiui.nav:hover .dropdown-toggle {
    min-width: 200px;
    width: 200px;
    transition: width .3s;
}

.ladiui.nav:hover .ladiui.menu-child {
    position: relative;
    opacity: 1;
    width: 100%;
}

.ladiui.menu-child {
    position: absolute;
    opacity: 0;
    width: 0;
    transition: opacity .2s;
}

.ladiui.menu-child-item {
    padding: 12px 0 12px 34px;
}

.ladiui.menu-child-item:first-child {
    padding: 24px 0 12px 34px;
}

.ladiui.menu-child li a:hover {
    color: var(--main-primary);
}

.ladiui.menu-child li a.active {
    color: var(--main-primary);
}


.ladiui.menu-child li a {
    color: var(--main-secondary);
}

.ladiui.menu-text {
    white-space: nowrap;
    padding-left: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}


.ladiui.head-noti {
    margin-bottom: 4px;
}

.ladiui.tag-form {
    display: inline-table;
}

.ladiui.dropdown-menu.custom-header {
    min-width: 200px;
}

.facet-container {
    width: 330px;
}

.right {
    float: right;
}

.left {
    float: left;
}

.facet-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-right: 10px;
    background: #eee;
    padding: 5px;
    width: 143px;
    min-height: 1.5em;
    font-size: 0.85em;
}

.facet-list li {
    margin: 5px;
    padding: 5px;
    font-size: 1.2em;
    width: 120px;
}

.facet-list li.placeholder {
    height: 1.2em
}

.facet {
    border: 1px solid #bbb;
    background-color: #fafafa;
    cursor: move;
}

.facet.ui-sortable-helper {
    opacity: 0.5;
}

.placeholder {
    border: 1px solid orange;
    background-color: #fffffd;
}

/* width */
::-webkit-scrollbar {
    width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--main-white);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}