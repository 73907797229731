.ladiui.block-banner-affiliate.custom-v2 {
    width: 100%;
}

.ladiui.block-banner-affiliate.custom-v2 a img {
    width: 100%;
    object-fit: cover;
}

.ladi-popupx-inline[data-ladipage-id="66418f519ae79400116994ca"] .ladi-popupx {
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
}

#ladi-popupx-backdrop {
    pointer-events: none !important;
}

.ladiui.affiliate-account-box .membership-class-group {
    display: flex;
    align-items: center;
    gap: 14px;
}

.ladiui.affiliate-account-box .membership-class-group .item {
    padding: 2px 12px;
    border-radius: 2px;
}

.ladiui.affiliate-account-box .membership-class-group .item span {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
}

.ladiui.affiliate-account-box .membership-class-group .item.Silver {
    background-color: #A2A2A2;
}
.ladiui.affiliate-account-box .membership-class-group .item.Gold {
    background-color: #F2A91B;
}
.ladiui.affiliate-account-box .membership-class-group .item.Diamond {
    background-color: #1B97F2;
}

.ladiui.affiliate-account-box .item-link-help {
    position: relative;
}

.ladiui.affiliate-account-box .item-link-help span {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    color: #1C00C2;
}

.tooltip .tooltiptext.max-w-275:before {
    max-width: 275px !important;
}

.affiliate-utm-source {
    overflow: hidden;
    max-width: 150px;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    line-clamp: 1;
    -webkit-box-orient: vertical;
}