.DateRangePickerInput {
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #ebedf2 !important;
    border-radius: 2px !important;
    height: 36px;
}

.DateInput_input__focused {
    border-color: #fff !important;
}

.DateInput_fang {
    top: 38px !important;
}

.CalendarMonth_table {
    margin-top: 10px;
}

.CalendarMonth_caption::first-letter {
    text-transform: capitalize !important;
}

.CalendarMonth_caption {
    font-size: 16px !important;
    font-weight: 500;
}

.CalendarDay__selected_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected {
    background: #0b61f2 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__selected:hover {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #5578eac7 !important;
    border: 1px double #ffffff !important;
    color: #fff !important;
}

.CalendarDay {
    vertical-align: middle !important;
}

.DateInput_input::-webkit-input-placeholder,
.DateInput_input::placeholder {
    font-size: 13px !important;
}

.DateRangePickerInput_calendarIcon i {
    vertical-align: middle !important;
}

.DateInput_input {
    font-weight: inherit !important;
    height: 30px;
}

.DateRangePicker .custom-btn-date-panel {
    padding: 0px 22px 11px 22px;
}

.DateRangePicker .custom-btn-date {
    position: relative;
    height: 100%;
    text-align: left;
    background: none;
    border: 1px solid #0b61f2;
    border-radius: 2px;
    color: #0b61f2;
    padding: 4px;
    margin-right: 16px;
    margin-bottom: 5px;
    font-weight: 500;
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
    cursor: pointer;
    font-size: 13px;
}

.DateRangePicker .custom-btn-date:active {
    outline: 0;
}

.DateRangePicker .custom-btn-date.selected {
    color: #fff;
    background: #0b61f2;
}

.DayPickerNavigation_button__horizontalDefault,
.DayPicker_transitionContainer,
.DayPicker__withBorder {
    border-radius: 2px;
}

.DateRangePickerInput__showClearDates {
    padding-right: 0;
}

.DateInput_input__small {
    font-size: 14px;
}

.DateRangePickerInput_clearDates {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    display: flex;
}

.custom-btn-date-panel {
    display: flex;
    justify-content: left;
}

.DateRangePicker .custom-btn-date:last-child {
    margin-right: 0;
}