@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    width: 100%;
    font-size: 14px;
    color: #464646;
    --notification-height: 0px;
}

.icon-menu-dashboards {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-dashboard.svg) no-repeat 50% 50%;
}

.icon-menu-affiliates {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-affiliate.svg) no-repeat 50% 50%;
}

.icon-menu-customers {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-customer.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-customer.svg) no-repeat 50% 50%;
}

.icon-menu-links {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-link.svg) no-repeat 50% 50%;
}

.icon-menu-logout {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logout.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-logout.svg) no-repeat 50% 50%;
}

.icon-menu-commissions {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-commission.svg) no-repeat 50% 50%;
}

.icon-menu-purchase {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladishare/icon-purchase.svg) no-repeat 50% 50%;
}

.icon-menu-campaigns {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-campain.svg) no-repeat 50% 50%;
}

.icon-menu-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-invoice.svg) no-repeat 50% 50%;
}

.icon-menu-request-invoices {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment2.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-payment2.svg) no-repeat 50% 50%;
}

.icon-menu-more {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-overflow-menu-horizontal.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-overflow-menu-horizontal.svg) no-repeat 50% 50%;
}

.icon-menu-account-setting {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
}

.icon-menu-reports {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-report.svg) no-repeat 50% 50%;
}

.icon-menu-sources {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-source.svg) no-repeat 50% 50%;
}

.icon-menu-apps {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-app.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-app.svg) no-repeat 50% 50%;
}

.icon-menu-settings {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiflow/assets/icon-setting.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiflow/assets/icon-setting.svg) no-repeat 50% 50%;
}

.icon-help-documents {
    pointer-events: none;
    background-color: var(--main-secondary);
    box-sizing: unset;
    width: 24px;
    height: 24px;
    display: inline-block;
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-helper-document.svg) no-repeat 50% 50%;
    mask: url(https://w.ladicdn.com/ladiui/icons/icon-ldp-helper-document.svg) no-repeat 50% 50%;
}

th.sort {
    position: relative;
    cursor: pointer;
}

th.sort .sort-icon {
    background: url(https://w.ladicdn.com/ladiui/ladipage/icon-sort-landingpage.svg);
    width: 12px;
    height: 12px;
    margin-left: 5px;
    margin-bottom: 3px;
    display: inline-block;
}

th.sort.sort-desc .sort-icon {
    background: url(https://w.ladicdn.com/ladiui/ladipage/icon-sort-ascending.svg);
}

th.sort.sort-asc .sort-icon {
    background: url(https://w.ladicdn.com/ladiui/ladipage/icon-sort-descending.svg);
}

.ladiui.page-container a.active {
    background-color: rgba(0, 0, 0, .1);
    border-radius: 2px;
    cursor: default;
}

.hidden {
    display: none !important;
}

.w-100 {
    width: 100px !important;
}

.w-175 {
    width: 175px !important;
}

.w-576 {
    width: 576px !important;
}

.w-553 {
    width: 553px !important;
}

.w-44 {
    width: 44px !important;
}

.w-440 {
    width: 440px !important;
}

.w-180 {
    width: 180px !important;
}

.w-220 {
    width: 220px !important;
}

.w-132 {
    width: 132px !important;
}

.w-100-percent {
    width: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.mt-0 {
    margin-top: 0 !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.mr-0 {
    margin-right: 0 !important;
}

.pt-0 {
    padding-top: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pr-0 {
    padding-right: 0 !important;
}

.m-12 {
    margin: 12px;
}

.ml-12 {
    margin-left: 12px !important;
}

.ml-16 {
    margin-left: 16px;
}

.ml-24 {
    margin-left: 24px;
}

.ml-32 {
    margin-left: 32px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-24 {
    margin-top: 24px !important;
}

.mt-48 {
    margin-top: 48px !important;
}

.mr-8 {
    margin-right: 8px !important;
}

.mt-4 {
    margin-top: 4px !important;
}

.mt-8 {
    margin-top: 8px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-22 {
    margin-top: 22px !important;
}

.mr-16 {
    margin-right: 16px;
}

.mr-50 {
    margin-right: 50px;
}

.mr-24 {
    margin-right: 24px;
}

.mb-10 {
    margin-bottom: 10px;
}

.mb-20 {
    margin-bottom: 20px;
}

.pt-10 {
    padding-top: 10px;
}

.pt-20 {
    padding-top: 20px;
}

.bold-500 {
    font-weight: 500;
}

.font-size-24 {
    font-size: 24px !important;
}

.font-size-18 {
    font-size: 18px !important;
}

.font-size-16 {
    font-size: 16px !important;
}

.font-size-14 {
    font-size: 14px !important;
}

.font-size-13 {
    font-size: 13px !important;
}

.font-size-12 {
    font-size: 12px !important;
}

.font-size-11 {
    font-size: 11px !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.clearfix {
    clear: both;
}

.gap-12 {
    gap: 12px;
}

.gap-16 {
    gap: 16px;
}

.gap-20 {
    gap: 20px;
}

.gap-24 {
    gap: 24px !important;
}

.btn-size-34 {
    height: 34px !important;
}

.ladiui.item.bg-form.bg-commissionn-plan {
    background-color: #F1F3F4;
}

.group-input-custom {
    position: relative;
}

.ladiui.btn-group.btn-group-custom {
    position: absolute;
    top: 6px;
    right: 12px;
    background: #F1F3F4;
    max-width: 60px;
    border-radius: 2px;
    text-align: center;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-menu {
    min-width: 0px;
    max-width: 60px;
}

.ladiui.btn-group.btn-group-custom .btn-group .dropdown-toggle {
    max-width: 60px;
    min-width: 34px;
    padding: 0 6px;
    height: 20px;
    line-height: 1;
    background: #F1F3F4;
}

.ladiui.btn-group.btn-group-custom .btn-group {
    max-width: 60px;
    min-width: 34px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px;
}

.ladiui.btn-group.btn-group-custom .ladiui.dropdown-item {
    padding: 5px;
}

#create-ladishare-comission-plan .ladiui.switch {
    margin: -3px 10px 0px 10px !important;
}

.box-commission-plan {
    margin-top: 32px;
}

.box-commission-plan .card-header {
    font-size: 16px !important;
}

.ladiui.badge {
    border-radius: 2px;
    /* height: 20px; */
    padding: 2px 6px 2px 6px;
    color: #fff;
    font-size: 12px;
}

.ladiui.badge.badge-success {
    background-color: #41B983;
}

.ladiui.badge.badge-warning {
    background-color: #E89D35;
}

.ladiui.badge.badge-mute {
    background-color: #727272;
}

.ladiui.card {
    border: 1px solid #F1F3F4;
}

.ladiui.card-header {
    background-color: #F1F3F4;
    border-bottom-color: #F1F3F4;
}

.box-commission-plan .ladiui.btn-group {
    padding: 0;
}

.box-commission-plan .ladiui.btn-group .ladiui-btn-dropdown.dropdown-toggle {
    transform: rotate(90deg);
    margin-right: -8px;
}

.ladiui.table-list-commission-plan-payment {
    margin-top: 10px;
    min-width: 0;
}

.ladiui.table-list-commission-plan-payment tr {
    border-bottom: 1px solid #EAEAEA;
}

.ladiui.table-list-commission-plan-payment tbody tr:last-child {
    border: none;
}

.ladiui.table-list-commission-plan-payment th,
.ladiui.small-label {
    font-size: 14px;
    font-weight: 400;
    color: #727272 !important;
}

.ladiui.table-list-commission-plan-payment tr td,
.ladiui.table-list-commission-plan-payment tr th {
    padding: 12px !important;
}

.ladiui.table-list-commission-plan-payment tr td:first-child {
    padding-left: 0;
}

.ladiui.content-left {
    width: 318px;
    max-width: 318px;
}

.ladiui.content-right {
    width: calc(100% - 318px);
    max-width: calc(100% - 318px);
    min-width: auto;
}

.ladiui .info-affiliate-detail .info-email {
    color: #727272;
}

.ladiui.group-info {
    padding: 12px 0;
}

.ladiui.group-info p {
    padding-top: 8px;
}

.ladiui.group-info p span {
    color: #41B983;
}

.ladiui.divider {
    margin: 12px -24px;
    border: 1px solid #F1F3F4;
}

.color-grey {
    color: #727272;
}

.ladiui.content-main-title.align-item-top,
.align-item-top {
    align-items: flex-start !important;
}

.ladiui.box-statistics {
    border-radius: 2px;
    height: 94px;
    border: 1px solid #F1F3F4;
    width: calc((100% - 72px)/4);
    margin: 12px 0;
    padding: 16px;
    box-sizing: border-box;
    align-items: center;
    display: flex;
    justify-content: inherit;
}

.ladiui.box-statistics.box-no-padding {
    padding: 0;
}

.ladiui.box-statistics.box-no-heigh {
    height: auto;
}

.ladiui.box-statistics .box-title {
    padding: 12px 24px;
    justify-content: inherit;
    border-bottom: 1px solid #F1F3F4;
}

.ladiui.box-statistics .box-title label {
    font-weight: 500;
}

.ladiui .box-statistics-icon {
    border-radius: 50%;
    background: #F1F3F4;
    width: 48px;
    height: 48px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ladiui.box-statistics .decrease,
.ladiui.box-statistics .increase {
    font-size: 10px;
}

.ladiui.box-statistics .increase {
    color: #41B983;
}

.ladiui.box-statistics .decrease {
    color: #D94F45;
}

.ladiui.box-statistics.box-bg-grey {
    background-color: #F1F3F4;
    height: 82px;
    width: calc((100% - 48px)/3);
}

.ladiui ul.history-action {
    border-left: 1px dashed #F1F3F4;
    margin-left: 24px;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 12px;
}

.ladiui ul.history-action li {
    margin: 12px 0;
    position: relative;
}

.ladiui ul.history-action li .box-statistics-icon {
    width: 24px;
    height: 24px;
    position: absolute;
    left: -36px;
    top: calc((100% - 24px)/2);
}

.ladiui ul.history-action li .box-statistics-icon img {
    width: 14px;
    height: 14px;
}

.ladiui ul.history-action li .title {
    font-weight: 500;
    font-size: 14px;
    color: #202020;
    line-height: 20px;
}

.ladiui ul.history-action li .content {
    font-size: 14px;
    line-height: 20px;
}

.ladiui ul.history-action li .content>label {
    font-weight: 500;
}

.ladiui ul.history-action li .content>div {
    font-size: 13px;
    display: flex;
    gap: 4px;
}

.ladiui ul.history-action li .time {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #AFAFAF;
}

.ladiui.new-tab-custom {
    position: relative;
    z-index: 0;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    background-color: #fff;
    transition: width .3s;
    overflow: hidden;
}

.ladiui.new-tab-custom:hover {
    display: flex;
    width: auto;
}

.ladiui.affiliate-timeline-note {
    margin-top: 40px;
    align-items: baseline;
    justify-content: space-between;
}

.ladiui.affiliate-timeline-note>div {
    width: calc((100% - 24px)/2);
}

.ladiui.affiliate-timeline-note .box {
    border: 1px solid #F1F3F4;
    padding: 16px;
    margin-top: 8px;
    border-radius: 2px;
}

.ladiui.affiliate-timeline-note .box.box-note {
    /* min-height: 150px; */
    white-space: pre-wrap;
}

.ladiui.content-main {
    height: auto;
    min-height: 91.5vh;
}

.ladiui.btn-sm-custom {
    height: 34px;
}

.ladiui.btn-sm-custom .ladiui.dropdown-text {
    margin-right: 20px;
}

.ladiui.table-scroll {
    overflow: auto;
}

.ladiui.table {
    min-width: auto;
}

.ladiui.text-pre {
    white-space: pre;
}

.ladiui.table-padding th,
.ladiui.table-padding td {
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.icon-circle {
    background-color: #fff;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ladiui.table-vertical td {
    color: #202020;
}

.color-1 {
    color: #464646 !important;
}

.color-2 {
    color: #202020 !important;
}

.ladiui.subtitle-2.content-tag-text.active {
    color: #1C00C2 !important;
}

.ladiui.title-text-form-lever2.margin-label {
    margin: 12px 68px 12px 0;
}

.edit-info-affiliate {
    color: #1C00C2;
}

.edit-info-affiliate i {
    background-color: #1C00C2 !important;
}

.filter-condtion-area {
    position: relative;
}

.list-filter-condition {
    display: none;
    position: absolute;
    min-width: 100px;
    background: #FFFFFF;
    box-shadow: 0px 3px 15px rgba(169, 184, 200, 0.15);
    border-radius: 2px;
    padding: 16px;
    margin-top: 5px;
    opacity: 1;
    z-index: 1;

}

.list-filter-condition.show {
    display: block;
}

.list-filter-condition label:not(.search-multi) {
    width: 80px;
}

.list-filter-condition .ladiui.dropdown-toggle {
    min-width: 110px;
}

.list-filter-condition input:not(.size-checkbox) {
    min-width: 120px;
}

.list-filter-condition input.DateInput_input {
    min-width: 0;
}

.list-filter-condition button.select-dropdown,
.list-filter-condition .dropdown-menu .dropdown-item {
    text-transform: lowercase;
}

.list-filter-condition button.select-dropdown .dropdown-text::first-letter,
.list-filter-condition .dropdown-menu .dropdown-item::first-letter {
    text-transform: uppercase;
}

#filter-condtion-area span.ladiui.btn-tag {
    text-transform: lowercase;
}

#filter-condtion-area span.ladiui.btn-tag::first-letter {
    text-transform: uppercase;
}

.ladiui.content-main-tool.align-item-baseline {
    height: auto !important;
    margin-top: 0;
}

.align-item-baseline {
    align-items: baseline !important;
}

.result-filter-condition {
    position: absolute;
}

.result-filter-condition span {
    margin-top: 10px;
    margin-right: 12px;
}

.ladiui.btn-tag {
    background: #F1F3F4;
    border-radius: 2px;
    height: 24px;
    color: #202020;
    font-size: 14px;
    line-height: 24px;
    padding: 0 6px;
    white-space: pre;
}

.cursor-pointer {
    cursor: pointer;
}

.ladiui.form-control {
    border-radius: 2px;
}

.ladiui.general-setting-content label {
    min-width: 130px;
    margin-bottom: 0;
}

.ladiui.general-setting-content {
    max-width: 600px;
    position: relative;
}

.ladiui.note-content {
    position: relative;
}

.ladiui.note-content button.btn-link {
    position: absolute;
    top: -5px;
    right: -5px;
}

.affiliate-setting-content,
.email-notify-setting-content {
    width: 614px;
}

.affiliate-setting-content>div.flex-row,
.email-notify-setting-content>div.flex-row {
    align-items: baseline;
    justify-content: space-between;
}

.affiliate-setting-content>div.flex-row .label-setting label {
    font-size: 16px;
}

.affiliate-setting-content>div.flex-row .label-setting div {
    color: #6D6D6D;
}

.email-notify-setting-content>div.flex-row {
    gap: 12px;
}

.email-notify-setting-content>div label {
    width: 30%;
}

.ladiui.summary {
    background: #F1F3F4;
    border-radius: 2px;
}

.ladiui.summary td {
    padding: 10px 0px 10px 0;
}

.ladiui.summary td span.total-record {
    color: #AFAFAF;
}

#modal-detail-commission {
    color: #202020;
}

#modal-detail-commission .item-detail-commission {
    padding: 10px 0;
    flex: 1 0 31%;
    margin: 5px;
}

#modal-detail-commission .item-detail-commission label {
    color: var(--main-secondary);
    font-weight: 400;
}

#modal-detail-commission .flex-item-detail-commission {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-custom .btn-group {
    width: 175px !important;
}

.ladiui.modal .dropdown-custom .btn-group .dropdown-toggle span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.ladi-check-all-action a {
    font-weight: normal;
}

.ladiui.content-main-full {
    padding-top: calc(var(--notification-height) + 65px);
}

.ladiui.content-main-title {
    padding: 20px 0;
}

.ladiui.content-main-tool>.ladiui.btn-group {
    padding: 0;
}

.list-app-integrate {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
}

.list-app-integrate.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
}

.box-app-integrate {
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    padding: 8px;
}

.box-app-integrate.box-new {
    border: 1px solid #1C00C2;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    min-height: 140px;
}

.box-app-integrate.box-new img {
    width: 21px;
    margin: 0 10px;
}

.box-app-integrate.box-new button.btn.btn-link {
    font-weight: 500;
    color: #1C00C2;
}

.box-app-integrate-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 8px;
}

.box-app-integrate-logo {
    padding-left: 8px;
}

.box-app-integrate-logo img {
    height: 24px;
}

.box-app-integrate-option button.ladiui.btn {
    padding: 0px 4px;
    margin-left: 8px;
}

.box-app-integrate-content-label {
    padding: 8px;
    font-weight: 500;
    color: #3E526A;
}

.box-app-integrate-content-description {
    padding: 8px;
    color: #6E7D8F;
}

.list-app-integrate.grid-2 .box-app-integrate:hover {
    border: 1px solid #1C00C2;
    cursor: pointer;
}

.modal-back {
    position: absolute;
    left: 10px;
}

#modal-add-integrate-app input:read-only,
#modal-setting-integrate-app input:read-only {
    background-color: #fbfbfb;
}

input:disabled {
    background-color: #e8eaeb !important;
}

#modal-detail-invoice .modal-body,
#modal-detail-invoice-request .modal-body {
    color: #202020;
}

#modal-detail-invoice .modal-body .invoice-code,
#modal-detail-invoice-request .modal-body .invoice-code {
    font-size: 12px;
    text-align: center;
}

#modal-detail-invoice .modal-body label,
#modal-detail-invoice-request .modal-body label {
    color: #6D6D6D;
    font-size: 14px;
}

#modal-detail-invoice .modal-body .detail-invoice,
#modal-detail-invoice-request .modal-body .detail-invoice {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
    margin-top: 28px;
}

#modal-detail-invoice .modal-body .detail-invoice-total,
#modal-detail-invoice-request .modal-body .detail-invoice-total {
    border: 1px solid #F1F3F4;
    padding: 20px;
}

#modal-detail-invoice .modal-body .detail-invoice-total div,
#modal-detail-invoice-request .modal-body .detail-invoice-total div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0;
}

#modal-detail-invoice .modal-body .detail-invoice-total hr,
#modal-detail-invoice-request .modal-body .detail-invoice-total hr {
    margin: 5px auto;
    border: none;
    border-top: 1px solid #F1F3F4;
}

#modal-detail-invoice .modal-body .detail-invoice-total .detail-invoice-total-total div,
#modal-detail-invoice-request .modal-body .detail-invoice-total .detail-invoice-total-total div {
    font-weight: 500;
    font-size: 20px;
}

.detail-invoice-action {
    display: inline-flex;
    margin-top: 14px;
}

.info-invoice {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
    margin-top: 14px;
}

.info-invoice div div {
    margin-top: 10px;
}

.detail-invoice-affiliate-code,
.detail-invoice-note {
    margin-top: 24px;
}

.detail-invoice-affiliate-name div,
.detail-invoice-affiliate-code div,
.detail-invoice-note div {
    margin-top: 8px;
}

.ladiui.my-account-avata,
.ladiui.avt-text {
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    background: #e7e7e7;
    font-size: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.ladiui.avt-text {
    width: 64px;
    height: 64px;
}

.statistics-top-affiliate {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 14px;
    margin-top: 28px;
}

.statistics-top-affiliate>div {
    border: 1px solid #F1F3F4;
    padding: 8px 0;
}

.statistics-top-affiliate .title {
    border-bottom: 1px solid #F1F3F4;
    padding: 0 12px 8px 12px;
    font-weight: 500;
    font-size: 14px;
}

.statistics-top-affiliate .content {
    padding: 0 12px;
    font-size: 14px;
}

/* #modal-select-platform .modal-dialog {
    width: 400px;
} */

#modal-select-platform .modal-dialog .ladiui.modal-body {
    margin: 0;
    padding: 0;
}

#modal-select-platform .box-app-integrate {
    display: flex;
    gap: 10px;
    min-height: 108px;
    align-items: center;
}

#modal-select-platform .box-app-integrate-content label {
    font-weight: 500;
    padding-bottom: 10px;
    display: block;
    color: var(--main-default);
    cursor: pointer;
}

/* .list-platform li {
    display: flex;
    padding: 12px 24px;
    cursor: pointer;
}

.list-platform li .icon-platform {
    margin-right: 24px;
    align-items: center;
    display: flex;
}

.list-platform li .icon-platform img {
    width: 28px;
    height: 28px;
    object-fit: cover;
}

.list-platform li:hover {
    background: #F1F3F4;
    transition: all 0.3s;
}

.list-platform li label {
    color: #202020;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.list-platform li p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
} */

.campaign-detail-title label,
.campaign-detail-options,
.campaign-detail-url {
    width: 50%;
}

.campaign-detail-title label {
    margin-left: 16px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #202020;
}

.campaign-detail-options {
    align-items: center;
    justify-content: space-between;
    margin-top: 16px;
}

.campaign-detail-options label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #AFAFAF;
}

.campaign-detail-options div.bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    margin-top: 8px;
}

.campaign-detail-url {
    margin-top: 16px;
}

.campaign-detail-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-top: 16px;
}

.campaign-detail-content label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #202020;
}

.campaign-detail-content .content-left-detail {
    margin-top: 8px;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 5px;
    min-height: 100px;
    padding: 16px;
}

.campaign-detail-content .content-right .label-share {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #AFAFAF;
}

.campaign-detail-content .content-right .number-share {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.15px;
    color: #000000;
    margin-left: 16px;
}

.lowercase {
    text-transform: lowercase;
}

.campaign-society-platform .custom-form,
.campaign-society-platform .ladiui.dropdown,
.campaign-society-platform .ladiui.dropdown-menu {
    width: 100% !important;
}

.tox .tox-notification {
    display: none !important
}

.item-campaign {
    padding: 16px 0;
    border-bottom: 1px solid #F1F3F4;
}

.item-campaign:first-child {
    padding-top: 0;
}

.item-campaign:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.ladiui.modal-footer.custom-ldp1 {
    justify-content: end;
}

.reference-no {
    display: block;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.ladiui.col-first {
    width: 35px;
}

.ladiui.dropdown {
    position: relative;
}

#modal-coupon-code .ladiui.dropdown,
#modal-coupon-code.ladiui.modal .btn-group,
#modal-coupon-code.ladiui.modal .btn-group .dropdown-toggle,
#modal-coupon-code.ladiui.modal .btn-group .dropdown-menu {
    width: 100%;
}

#modal-coupon-code input.ladiui.form-control {
    height: 36px;
}

#modal-campaign-share .btn-group,
#modal-campaign-share .btn-group .dropdown-toggle {
    width: inherit;
}

.cursor-copy {
    cursor: copy;
}

.dropdown-setting-custom .btn-group.custom-form {
    padding-bottom: 0;
}

.dropdown-menu.dropdown-right {
    right: 0;
}

.icon-ldp {
    font-family: 'LadiIcons';
}

.icon-ldp.icon-2x {
    font-size: 1.5em;
}

.api-key-action {
    cursor: pointer;
    position: absolute;
    top: 8px;
    right: 15px;
}

.box-api-key {
    position: relative;
    width: 100%;
}

.box-api-key .icon-ldp-on-access-key {
    position: absolute;
    left: 15px;
    top: 10px;
}

.box-api-key input.form-control {
    padding-left: 40px;
}

.box-api-key .icon-ldp {
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 10px;
}

.box-app-discount {
    border-radius: 5px;
    background: #F1F3F4;
    padding: 16px;
    margin: 10px 0;
}

.box-app-discount .form-group-custom {
    margin-top: 8px;
}

.aff-dashboard-setting-content,
.affiliate-account-box {
    width: 672px;
}

.aff-dashboard-setting-content .form-group-flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.aff-dashboard-setting-content .form-group.flex-start {
    justify-content: flex-start;
}

.aff-dashboard-setting-content .form-group>label {
    width: 172px;
}

.form-group-file-image {
    position: relative;
}

.form-group-file-image input {
    width: 1px;
    height: 1px;
}

.form-group-file-image._logo .img-preview {
    height: 56px;
    width: 160px;
    padding: 6px;
    background: #F1F3F4;
    border-radius: 2px;
}

.form-group-file-image._logo label {
    opacity: 0;
    transition: all 0.3s;
}

.form-group-file-image._logo:hover label {
    opacity: 1;
    transition: all 0.3s;
}

.form-group-file-image._icon {
    display: flex;
    align-items: center;
}

.form-group-file-image._icon .img-preview {
    height: 56px;
    width: 56px;
    background: #F1F3F4;
    border-radius: 2px;
}

.tox-statusbar__branding {
    display: none;
}

table.ladiui.table th {
    height: 40px;
    padding: 12px 6px 12px 6px;
    vertical-align: middle;
}

table.ladiui.table th.col-action {
    min-width: 150px;
}

.highlight-1 {
    text-transform: lowercase;
    vertical-align: baseline;
}

.avartar .ladiui.body-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-history {
    position: relative;
}

.link-to-report {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

.upload-img-banner {}

.upload-img-banner input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
}

.upload-img-banner .list-img-banner-preview {
    display: grid;
    gap: 12px;
    flex-wrap: wrap;
    grid-template-columns: repeat(3, 1fr);
}

.upload-img-banner .list-img-banner-preview .img-banner-preview {
    height: 88px;
    border-radius: 2px;
    background-color: #e7e7e7;
    width: 100%;
    position: relative;
}

.upload-img-banner .list-img-banner-preview .img-banner-preview .remove-img-banner-preview {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 5px;
    right: 5px;
    background: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.upload-img-banner .list-img-banner-preview .img-banner-preview .remove-img-banner-preview:hover {
    background-color: #f7f7f7;
}

.upload-img-banner .list-img-banner-preview .img-banner-preview>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.upload-img-banner button:hover img {
    filter: brightness(0) invert(1);
}

.list-share-banner-img .list-share-banner-img-item {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-img {
    border-radius: 2px;
    width: 220px;
    height: 120px;
    object-fit: cover;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded {
    display: block;
    width: calc(100% - 240px);
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-embeded textarea {
    padding: 8px 32px 8px 12px;
    height: 98px;
    background: #FFFFFF;
    border: 1px solid #F1F3F4;
    border-radius: 2px;
    margin-top: 2px;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-download {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    top: 0;
    justify-content: center;
    align-items: center;
    display: none;
}

.list-share-banner-img .list-share-banner-img-item .list-share-banner-img-item-image:hover .list-share-banner-img-item-download {
    display: flex;
}

.summary-box {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 24px;
    overflow: hidden;
}

.ladiui.setting-content-left {
    z-index: 1;
    background-color: #FFFFFF;
    height: 100%;
    border-right: 1px solid var(--main-line);
}

.info-package-item {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    padding: 12px;
}

.detail-invoice-print {
    width: 1024px;
    margin: 50px auto;
}

.box-print-button {
    align-items: center;
    display: flex;
    justify-content: center;
}

.detail-invoice-print .box-code {
    display: flex;
    justify-content: end;
    align-items: center;
}

.detail-invoice-print .box-code .code {
    text-transform: uppercase;
    font-weight: 500;
}

.detail-invoice-print .box-info-invoice .invoice-flex {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.detail-invoice-print .box-info-invoice label {
    font-weight: 500;
    padding: 12px 0;
    display: block;
}

.table-invoice-detail {
    border: 1px solid #cacccd;
}

.table-invoice-detail thead {
    background-color: #e7e7e7;
}

.table-invoice-detail thead th,
.table-invoice-detail tbody td {
    padding: 12px;
    border-right: 1px solid #cacccd;
}

.CalendarMonth_table tr td {
    padding: 0 !important;
}

#modal-purchase .time-expired-plan-payment .DateInput_input.DateInput_input_1,
#modal-invoice-selected-affiliate .time-expired-plan-payment .DateInput_input.DateInput_input_1 {
    height: 34px !important;
}

#modal-purchase .time-expired-plan-payment .SingleDatePicker.SingleDatePicker_1,
#modal-invoice-selected-affiliate .time-expired-plan-payment .SingleDatePicker.SingleDatePicker_1 {
    margin-top: 0;
}

#modal-purchase .time-expired-plan-payment .SingleDatePickerInput_clearDate,
#modal-invoice-selected-affiliate .time-expired-plan-payment .SingleDatePickerInput_clearDate {
    width: 28px;
    height: 28px;
    padding: 0;
}

.content-detail-commission-value {
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, 1fr);
}

.main-title-dashboard {
    display: none;
}

.highcharts-no-tooltip-box {
    width: 650px;
}

.ladiui.modal .btn-group.ladi-dropdown-multi-select,
.ladiui.modal .btn-group.ladi-dropdown-multi-select .ladiui.select-search.dropdown,
.ladiui.modal .btn-group.ladi-dropdown-multi-select .dropdown-menu {
    width: 100%;
}

.ladiui.modal .btn-group.ladi-dropdown-multi-select .dropdown-toggle,
.ladiui.modal .btn-group.ladi-dropdown-multi-select .ladiui.select-search .dropdown-text {
    width: 100%;
    max-width: 100%;
}

.ladiui.modal table.ladiui.table th {
    white-space: pre;
}

@media print {
    .no-print {
        display: none;
    }
}

.builder-loading {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: fixed;
    background-size: 5%;
    overflow: hidden;
    background-color: #fafafa;
    z-index: 900000080;
    background-size: 100px;
}

.builder-loading-blur {
    background-color: rgba(12, 97, 242, .05);
}

.ladiui.item-package {
    border-bottom: 1px solid var(--main-line);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px 0;
}

.ladiui.text-package-type {
    color: var(--main-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.ladiui.value-package-type.custom {
    color: var(--main-default);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
}

.ladiui.modal-footer.custom-package-type {
    padding: 35px 0 0;
}

.ladiui.modal-dialog.custom-package-type {
    width: 394px !important;
}

.bg-commissionn-plan .btn-group.custom-form .ladiui.dropdown-toggle::after {
    margin: 12px 14px 10px 16px !important;
}

.package-info {
    padding: 24px;
    border: 1px solid var(--main-line);
    width: 394px;
    margin-top: 36px;
}

.ladiui.account-header-text {
    pointer-events: all;
}

.shop-id-comment {
    font-size: 0.9em;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #666;
}

.ul-selected {
    border-bottom: 1px solid var(--main-line);
}

.ladi-dropdown-multi-select .ladiui.select-search.dropdown .ladiui-btn-tag {
    display: block;
}

.custom-dropdown-text-1 {
    margin-right: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-option-check li {
    padding: 8px 0;
    display: flex;
    justify-content: left;
    align-items: center;
}

.list-option-check {
    margin-top: 12px;
    margin-left: 24px;
}

.list-option-check li label {
    color: #464646;
    font-size: 14px !important;
}

.list-option-check li label input {
    margin-right: 12px;
}

.ladiui.access.content-main-tool .search-group {
    width: auto;
}

label.ladiui.title-text-form-lever3 {
    margin-bottom: 3px;
    margin-left: 3px;
    font-size: 13px;
}

#create-ladishare-comission-plan .check-switch-2 .ladiui.switch {
    margin-top: 8px !important;
    margin-left: 20px !important;
}

.tooltip .tooltiptext.top::before {
    max-width: 250px;
}

.detail-info-commission .label-info,
.detail-total-commission .label-info {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.detail-info-commission .label-info-2,
.detail-total-commission .label-info-2 {
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.detail-total-commission {
    border: 1px solid #EEEEEE;
    border-radius: 2px;
    padding: 20px;
    align-items: center;
}

.detail-total-commission>div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail-total-commission-value {
    font-size: 20px;
    font-weight: 500;
    color: var(--main-default);
}

.detail-total-commission-value-group {
    border-top: 1px solid #EEEEEE;
    margin-top: 10px;
    padding-top: 12px;
}

.ladiui.status-starter {
    width: auto;
}

.btn-change-group-setting {
    display: flex !important;
    align-items: center;
}

.btn-change-group-setting,
.btn-change-group-setting:hover {
    color: var(--main-primary) !important;
}

.btn-change-group-setting .icon-menu-settings {
    -webkit-mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    background-size: 16px;
    background-color: var(--main-primary);
    mask: url(https://w.ladicdn.com/ladiui/icons/ldicon-setting.svg) no-repeat 50% 50%;
    mask-size: 16px;

}

.SingleDatePickerInput__withBorder {
    border: 1px solid var(--secondary-color) !important;
}

.commission-date-input .DateInput__small {
    width: 100%;
}

.commission-date-input .SingleDatePickerInput__showClearDate {
    height: 32px;
}

.commission-date-input.commission-date-input-size-34 .SingleDatePickerInput__showClearDate {
    height: 34px;
}

.commission-date-input .SingleDatePickerInput__showClearDate {
    padding-right: 0;
    display: flex;
    align-items: center;
}

.commission-date-input .SingleDatePickerInput_clearDate__small {
    background-color: #FFFFFF;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

::-webkit-scrollbar-thumb {
    background: var(--main-mute);
    border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
    background: var(--main-secondary);
}

.input-ref-register:read-only,
.input-ref-register:read-only:focus {
    border: none;
    background-color: var(--secondary-color);
}

.ladiui.dropdown-toggle.import-data-btn::after {
    content: none;
}

.dropdown-status-affiliate,
.dropdown-status-affiliate .ladiui.btn-group,
.ladiui.modal .dropdown-status-affiliate .btn-group .dropdown-toggle,
.ladiui.modal .dropdown-status-affiliate .btn-group .dropdown-menu {
    width: 100%;
}

.dropdown-status-affiliate .ladiui.btn-group {
    display: block;
}

.ladiui.box-gray {
    padding: 16px;
    background: var(--main-line);
    border-radius: 5px;
    margin-top: 10px;
}

.ladiui.box-white {
    background: var(--main-white);
    padding: 16px;
    border-radius: 5px;
    margin-top: 10px;
}

.ladiui.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ladiui.dropdown-menu {
    border-radius: 2px;
}

/* New style */
.ladiui.table-vertical.main {
    border-bottom: 1px solid var(--secondary-color);
}

.ladiui.table-vertical.main:last-child {
    border-bottom: none;
}

.ladiui.table-vertical td {
    padding: 12px 6px 12px 6px;
}

.d-grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
}

.d-grid-3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
}

.d-grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 12px;
}

.grid-template-177 {
    grid-template-columns: 177px 177px 177px 177px;
}

.time-expired-plan-payment .SingleDatePicker.SingleDatePicker_1 {
    margin-top: 1px;
}

.time-expired-plan-payment .DateInput.DateInput_1 {
    width: 83%;
}

.time-expired-plan-payment .SingleDatePickerInput_clearDate {
    margin-right: 5px;
}

.time-expired-plan-payment .SingleDatePickerInput__showClearDate {
    padding-right: 0;
}

.time-expired-plan-payment .SingleDatePickerInput_clearDate__hide {
    display: none;
}

.time-expired-plan-payment .SingleDatePickerInput_calendarIcon {
    margin: 0;
    padding: 4px;
}

.time-expired-plan-payment .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
    border: none;
}

.time-expired-plan-payment .DateInput_input.DateInput_input_1 {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    height: 30px;
    color: var(--main-black);
    background-color: var(--main-white);
    background-clip: padding-box;
    /* border: 1px solid var(--main-line); */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 2px;
}

.time-expired-plan-payment .SingleDatePickerInput_clearDate {
    padding: 4px;
    background-color: #fff;
}

.time-expired-plan-payment .SingleDatePickerInput__disabled {
    padding-right: 0 !important;
}

.time-expired-plan-payment .DateInput_input__disabled {
    font-style: normal;
    width: 100%;
}

.time-expired-plan-payment .SingleDatePickerInput.SingleDatePickerInput_1.SingleDatePickerInput__withBorder.SingleDatePickerInput__withBorder_2 {
    position: relative;
}

.time-expired-plan-payment .SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
    left: auto !important;
    top: 42px !important;
    right: 0 !important;
}

.time-expired-plan-payment .DateInput_fang.DateInput_fang_1 {
    top: 32px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
    display: none;
}

#create-ladishare-comission-plan .ladiui.dropdown-toggle::after {
    margin: 10px !important
}

#create-ladishare-comission-plan .ladiui.btn-group.btn-group-custom .ladiui.dropdown-toggle:after {
    margin: 5px !important;
}

.table-integrate-app-coupon {
    width: 100%;
}

.table-integrate-app-coupon tr td {
    padding: 4px !important;
}

.dropdown-custom-2 .btn,
.dropdown-custom-2 .dropdown-menu {
    min-width: 0;
    width: 120px !important;
}

#create-ladishare-comission-plan .ladiui.dropdown,
#create-ladishare-comission-plan .ladiui.btn-group,
#create-ladishare-comission-plan .ladiui.item-form.dropdown-custom {
    width: 100% !important;
}

#create-ladishare-comission-plan .dropdown-custom .btn-group .dropdown-menu,
#create-ladishare-comission-plan .dropdown-custom .btn-group .dropdown-toggle,
#create-ladishare-comission-plan .dropdown-custom .btn-group {
    width: 100% !important;
}

#create-ladishare-comission-plan .ladiui.select-search .btn {
    max-width: 100% !important;
}

#modal-setting-change-group .ladiui.switch {
    margin: 0 12px;
}

.ladiui.modal.modal-custom-2 .dropdown-custom .btn-group .dropdown-menu,
.ladiui.modal.modal-custom-2 .dropdown-custom .btn-group .dropdown-toggle,
.ladiui.modal.modal-custom-2 .dropdown-custom .btn-group,
.ladiui.modal.modal-custom-2 .ladiui.btn-group.custom-form-dynamic,
.ladiui.modal.modal-custom-2 .ladiui.btn-group,
.ladiui.modal.modal-custom-2 .ladiui.item-form.dropdown-custom,
.ladiui.modal.modal-custom-2 .ladiui.dropdown {
    width: 100% !important;
}

.get-short-link {
    position: absolute;
    right: 12px;
    top: 8px;
    color: #1C00C2;
}

.input-base-link {
    padding-right: 45px !important;
}

.button-copy-link {
    position: absolute;
    right: 12px;
    top: 8px;
    color: #1C00C2;
}

.input-copy-link {
    padding-right: 45px !important;
}

.img-bg-campaign {
    width: 100%;
    height: 200px;
    object-fit: cover;
    padding: 0.3rem;
}

.calendar-search {
    margin-left: 22px;
}

.more-click {
    display: none;
}

.main-search-condition {
    min-width: calc(100% - 210px);
}

.custom-dropdown-2 .ladiui.dropdown-text {
    margin-right: 10px;
}

.dropdown-310 .ladiui.dropdown-toggle {
    width: 310px;
}

.ladiui.dropdown-toggle::after {
    background: url(https://w.ladicdn.com/ladiui/icons/ldicon-chevron-down.svg) no-repeat center;
    background-size: contain;
}

.tox-tinymce {
    /* border: 1px solid var(--secondary-color) !important; */
    border-radius: 2px !important;
}

.dropdown-max-height .dropdown-menu {
    max-height: 250px !important;
    overflow-y: auto;
}

ul.list-group-aff li {
    display: inline-block;
    border: 1px solid var(--main-white);
    border-radius: 2px;
    padding: 0 6px !important;
    margin-right: 5px;
    margin-bottom: 5px;
    background: var(--main-white);
    max-width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;
}

ul.list-group-aff li.secondary-color {
    border: 1px solid var(--secondary-color);
    background: var(--secondary-color);
}

/* End new style */

/* Publisher CSS------------ */
.publisher-container {
    height: 100vh;
    min-height: 768px;
    background: #F1F3F4;
    display: grid;
    grid-template-columns: repeat(1, 0fr);
    align-items: center;
    justify-content: center;
}

.box-publisher {
    width: 792px;
    background: #FFFFFF;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    border-radius: 2px;
    position: relative;
    min-height: 570px;
}

.box-publisher .box-logo {
    position: absolute;
    /* top: -60px; */
    width: 100%;
    text-align: center;
}

.box-publisher .box-publisher-left {
    padding: 40px;
}

.box-publisher .box-publisher-right {
    padding: 40px;
    border-left: 1px solid #F1F3F4;
}

.publisher-footer {
    display: flex;
    position: absolute;
    bottom: -60px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.publisher-footer-item {
    text-align: center;
    border-left: 1px solid #C5C5C5;
    padding: 0 22px;
    color: #000000;
}

.publisher-footer-item img {
    padding-bottom: 5px;
    height: 25px;
    padding-left: 12px;
}

.publisher-footer-item a {
    color: #000000;
}

.publisher-footer-item:first-child {
    border-left: none
}

.publisher-container .box-publisher .box-title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
}

.publisher-container .box-publisher .box-publisher-right .box-title {
    text-align: left;
}

.publisher-container .box-publisher .box-publisher-right .box-sub-title {
    color: #AFAFAF;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.publisher-container .box-publisher .box-description {
    margin-top: 40px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
}

.publisher-container .box-publisher .box-publisher-right .btn {
    width: 100%;
    justify-content: center;
}

.publisher-container .box-publisher .box-publisher-right a.box-forgot-password {
    float: right;
    color: #6D6D6D;
    margin-top: 12px;
    margin-bottom: 21px;
}

.campaign-publisher-list {
    display: grid;
    grid-template-columns: repeat(5, minmax(17%, 1fr));
    gap: 1rem;
}

.publisher-box-campaign {
    border: 1px solid var(--secondary-color);
    border-radius: 4px;
}

.dashboard-left-publisher {
    width: 66%;
}

.dashboard-right-publisher {
    width: 33%;
}

/* End Publisher CSS----------- */