/* ----------------------------------------modal-------------------------------------------------------- */
.ladiui.modal .search-field {
    height: 32px;
}

.ladiui.modal .search-group i {
    top: 3px;
}

/*-----------------------------------------Ladipage Home---------------------------------------*/
.ladiui.content {
    height: 90vh;
    position: relative;
    top: 64px;
    padding: 0px 40px 0px 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.content-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.content-sub {
    max-width: 530px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.sub-weight {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--content-sub-color);
    vertical-align: baseline;
}

.ladiui.content-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
}

.ladiui.content-navigation .dropdown-toggle::after {
    content: none;
}

.ladiui.btn-primary {
    margin: 0px 0px 0px 0px;
}

.ladiui.end {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin: 0px 24px 0px 24px;
}

.ladiui.tutorial a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.tutorial {
    border-bottom: 1px solid var(--main-primary);
}

/*---------------------------------------------------------------------------------------Landingpage list-------------------------------------------------------------------------------*/
.ladiui.content-main {
    position: absolute;
    top: 64px;
    border-left: 1px solid var(--main-line);
    width: calc(100% - 236px);
    min-width: 1012px;
    height: 91.5vh;
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 24px 40px 24px;
}

.ladiui.content-main1 {
    position: absolute;
    top: 64px;
    width: 100%;
    min-width: 1012px;
    /* height: 82vh; */
    right: 0px;
    margin: 0px 0px 0px 0px;
    padding: 24px 20px 40px 24px;
}

.ladiui.content-main-full {
    padding: 88px 20px 40px 24px;
}

.ladiui.main-title {
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
}

.ladiui.content-list-landingpage {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: left;
}

.ladiui.content-main-title {
    justify-content: space-between;
}

.ladiui.content-tag {
    max-width: 236px;
    width: calc(100% - 236px);
    height: 100%;
    padding: 24px 24px 0px 23px;
    position: fixed;
    top: 64px;
    left: 74px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
}

.ladiui.content-tag-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.content-tag-setting {
    position: absolute;
    right: 24px;
}


.ladiui.btn-primary {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.btn-custom-img {
    padding: 9px 8px 9px 0px;
    pointer-events: none;
}

.ladiui.btn-custom-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
    pointer-events: none;
}

.ladiui.table {
    margin-top: 25px;
    min-width: 1012px;
    width: 100%;
}

.ladiui.btn-group.custom-btn-list-landingpage {
    margin-left: 24px;
}

.ladiui.content-main-tool {
    margin-top: 28px;
}

.ladiui.custom-btn-list-landingpage {
    min-width: 154px;
}

.ladiui-btn-tag.landingpage-list {
    margin: 4px 4px 4px 0px;
}

.ladiui-btn-tag.landingpage-list:hover {
    background-color: var(--main-primary);
    color: var(--main-white);
    cursor: pointer;
}

.ladiui-btn-tag.landingpage-list:active {
    background-color: var(--main-primary);
    color: var(--main-white);
}

.ladiui.content-main-tool {
    height: 36px;
}


/*--------------------------------------------------------------------------------Ladipage banner-ads-list------------------------------------------------------------------------------*/
.ladiui.dropdown-toggle.custom-pre-page {
    min-width: 64px;
}

.ladiui.dropdown-text.custom-pre-page {
    margin: 0;
    padding: 0px 16px 0px 0px;
}

.ladiui.dropdown-toggle.custom-pre-page:after {
    margin: 12px 12px 12px 0px;
}

.ladiui.dropdown-menu.custom-pre-page {
    min-width: 64px;
    bottom: 0;
}

.ladiui.btn-group.custom-pre-page {
    padding: 0;
}

.ladiui.from-filter {
    min-height: 32px;
}

.ladiui.showing {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-left: 20px;
    ;
}

.ladiui.showing span {
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    color: var(--main-default);
}

.ladiui.search-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
}

.ladiui.col-one.custom-th-banner {
    min-width: 344px;
}

.ladiui.setting-content {
    width: 236px;
    padding: 24px;
    word-wrap: break-word;
    background-color: var(--main-white);
    z-index: 99;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.ladiui.content-tag-text {
    color: var(--main-default);
}

.ladiui.ladiui.content-tag-text.active {
    color: var(--main-primary);
}

.ladiui.setting-name {
    margin-bottom: 18px;
}

.ladiui.setting-item {
    margin: 32px 0;
}

.ladiui.switch-content.form-vertical {
    margin-bottom: 10px;
}

.ladiui.form-vertical {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.ladiui.form-vertical:last-child {
    margin-bottom: 0;
}

.ladiui.input-name {
    width: 100px;
    margin-right: 24px;
}

.ladiui.setting-input-name {
    width: 150px;
    margin-right: 24px;
}

.ladiui.col-tow.custom-th-banner {
    min-width: 344px;
}

.ladiui.col-three.custom-th-banner {
    min-width: 269px;
}

/* -------------------------------------------------Setting---------------------------------------- */
.ladiui.setting.content-main table {
    margin-top: 18px;
}

.ladiui.setting-content a:hover {
    color: var(pr);
}

.ladiui.setting-content:last-child {
    border-bottom: none;
}

.ladiui.content-parent {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.setting-content-left {
    position: fixed;
    top: calc(var(--notification-height) + 65px);
}

.ladiui.shared.setting-content-right {
    width: 636px;
    padding-bottom: 18px;
}

.ladiui.setting-content-right:last-child {
    border-bottom: none;
}

.ladiui.setting-content-right .btn-group {
    margin: 0 20px 0 0;
}

.ladiui.setting-content-right .btn-group:first-child {
    margin-left: 0px;
}

.ladiui.content-parent .table {
    margin-top: 0;
}

.ladiui.content-parent:last-child {
    border-bottom: none;
    margin-bottom: 0;
}

.ladiui.switch-btn.form-vertical {
    justify-content: end;
    margin-top: 34px;
}

.ladiui.switch-btn.form-vertical button {
    margin-right: 24px;
}

.ladiui.switch-btn.form-vertical button:last-child {
    margin-right: 0;
}

.ladiui.col-six.custom-th-banner {
    min-width: 18px;
}

.ladiui.icon.icon-ldp-list {
    margin-right: 16px;
}



/*--------------------------------------------------------------------------------banner grid----------------------------------------------------------------------------------------*/

.ladiui.card-custom {
    width: 310px;
    height: 250px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.1));
    border-radius: 2px;
}

.ladiui.card-img-top-custom {
    width: 310px;
    height: 250px;
    position: absolute;
}


.ladiui.card-body-custom {
    background-color: var(--main-white);
    position: relative;
    height: 60px;
    top: 190px;
}

.ladiui.card-title-custom {
    display: inline-block;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin: 8px 0px 4px 17.47px;
}

.ladiui.text-time {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: var(--main-secondary);
    margin-left: 8.73px;
}

.ladiui.card-custom-date {
    margin: 0px 0px 0px 17.47px;
}

.ladiui.banner-ads-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    column-gap: 24px;
    row-gap: 24px;
    margin-top: 24px;
}

.ladiui.content-main-tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: end;
}

.ladiui.content-main-tool-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 6px;
}

.ladiui.table-show {
    display: none;
}

.ladiui.grid-show {
    display: none;
}

.ladiui.icon-list-view.tab-link,
.ladiui.icon-grid-view.tab-link {
    border-bottom: none;
}

/* ---------------------------------------------------------------------------------------intro---------------------------------------------------------------------------------- */
.ladiui.intro-content {
    padding-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ladiui.intro-head {
    margin-bottom: 19px;
}

.ladiui.intro-header {
    text-align: center;
    margin-bottom: 65px;
}

.ladiui.intro-body {
    display: flex;
    flex-direction: row;
    margin-bottom: 48px;
}

.ladiui.intro-body-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 243px;
    text-align: center;
    margin: 0 121px;
}

.ladiui.intro-body-content i {
    margin-bottom: 53px;
}

.ladiui.intro-body-content span {
    margin-bottom: 8px;
}

.ladiui.intro-footer {
    width: 528px;
    margin-bottom: 24px;
    text-align: center;
}

/*----------------------------------------------------------------------------------------custom table dynamic--------------------------------------------------------------------------------------------------------------*/
.ladiui.col-one.custom-dynamic {
    min-width: 324px;
}

.ladiui.col-tow.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-three.custom-dynamic {
    min-width: 161px;
}

.ladiui.col-threes.custom-dynamic {
    min-width: 193px;
}

.ladiui.col-for.custom-dynamic {
    min-width: 132px;
}

.ladiui.col-fors.custom-dynamic {
    min-width: 112px;
}

.ladiui.col-five.custom-dynamic {
    min-width: 112px;
}

/*Custom form dynamic*/
.ladiui.modal-content.custom-dynamic {
    min-width: 824px;
    padding: 24px;
}

.ladiui.modal-header.dynamic {
    padding: 0px 0px 4px 0px;
}

.ladiui.item.dynamic {
    margin-top: 32px;
}

.ladiui.btn-group-dynamic {
    padding: 0px;
}

.ladiui.btn-group.dynamic {
    padding: 0px;
}

.ladiui.item-form.dynamic {
    align-items: center;
}

.ladiui.switch.sw-lg.dynamic {
    margin: 0px;
}

.ladiui.item-form {
    margin: 0px;
}

.ladiui.item-form {
    padding: 0px;
}



.ladiui.search-group .dropdown-menu {
    min-width: 270px;
}


/*----------------------------------------------------------------------------------------Custom modal home----------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-ldp-width {
    width: 600px;
}

.ladiui.custom-ldp {
    padding: 24px 24px 24px 24px;
}

.ladiui.modal-header.custom-ldp {
    padding: 0px 0px 12px 0px;
}

.ladiui.form-group-custom {
    margin-top: 28px;
}

.ladiui.modal-footer.custom-ldp {
    padding: 48px 0px 0px 0px;
}

.ladiui.form-check-inputs-custom {
    margin-top: 32px;

}

.ladiui.radio.custom-ldp-radio {
    margin-top: 8px;
}

.ladiui.dropdown-toggle.custom-main-tool {
    margin-left: 24px;
}

.ladiui.search-width {
    min-width: 270px;
}

/*----------------------------------------------------------------------------------------custom modal popup--------------------------------------------------------------------------------------------------------------*/
.ladiui.modal-dialog.custom-popup-width {
    width: 718px;
}

.ladiui.label-embel-code {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--neutral-text-color);
    margin-top: 20px;
}

.ladiui.input-embel-code {
    margin-top: 16px;
}

/*----------------------------------------------------------------------------------------custom dropdown people--------------------------------------------------------------------------------------------------------------*/

.ladiui.dropdown-toggle.custom-people {
    min-width: 168px;
}

.ladiui.dropdown-menu.custom-people {
    position: absolute;
    left: 24px;
}

.ladiui.dropdown-menu.custom-people {
    padding: 0px;
}

/* --------------------------------------------------------------------------------------------Domain---------------------------------------------------------------------------------------------- */
.ladiui.domain .modal-dialog {
    width: 600px;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

.ladiui.modal .form-group:first-child {
    margin-top: 0;
}

.ladiui.modal .btn-group {
    padding: 0;
    width: 552px;
}

.ladiui.modal .btn-group .dropdown-toggle {
    width: 552px;
}

.ladiui.modal .btn-group .dropdown-menu {
    width: 552px;
}

.ladiui.domain .span-text {
    margin-right: 40px;
}

.ladiui.domain .table-vertical td {
    padding: 0;
}


.ladiui.modal .domain-action.btn-group {
    width: 120px;
    margin-right: 16px;
}

.ladiui.modal .domain-action.btn-group .dropdown-toggle {
    min-width: 120px;
    width: 120px;
}

.ladiui.modal .domain-action.btn-group .dropdown-menu {
    min-width: 120px;
    width: 120px;
}

/* -------------------------------------------------------------------------------------------------------------Member------------------------------------------------------------------------------------------------------ */
.ladiui.member .search-field {
    width: 295px;
}

.ladiui.member .custom-btn-list-landingpage {
    width: 188px;
}

.ladiui.member .table {
    margin-top: 44px;
}

.ladiui.member .ladiui.content-main-tool {
    margin-top: 18px;
}

.ladiui.member .col-one {
    min-width: 150px;
}

.ladiui.member .col-three {
    min-width: 150px;
}

.ladiui.member table .btn-group {
    padding: 11.5px 0;
}

.ladiui .modal .btn-group.decent {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-toggle {
    min-width: 222px;
    width: 222px;
}

.ladiui .modal .btn-group.decent .dropdown-menu {
    min-width: 222px;
    width: 222px;
}

.ladiui.auto-height.form-control {
    word-break: normal;
    word-wrap: break-word;
}

.ladiui.share-recource {
    display: grid;
    grid-template-columns: auto auto auto;
}

.ladiui.share-recource .span-text {
    margin: 0;
    padding: 8px 0 16px;
}

.ladiui.list-recource {
    display: none;
}

.ladiui.form-group .select-search .btn {
    max-width: none;
    text-overflow: unset;
}

.ladiui.form-group .select-search .dropdown-text {
    overflow: unset;
}

.ladiui.status.form-group .span-text {
    margin: 0 62px;
}

.ladiui.status.form-group .span-text:first-child {
    margin-left: 0;
}

.ladiui.status.form-group .span-text:last-child {
    margin-right: 0;
}

.ladiui.member .list-team .col-one {
    min-width: 220px;
}

.ladiui.member .list-team .col-five {
    min-width: 28px;
}

/*-----------------------------------------------------------------------------------------------------custom modal dropdown----------------------------------------------------------------------------------------*/
.ladiui.custom-ldp-width1 {
    min-width: 450px;
}

.ladiui.text.on-access-key {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
}

.ladiui.modal-footer.custom-ldp1 {
    padding: 24px 0px 0px 0px;
    justify-content: left;
}

.ladiui.form-check-inputs-custom1 {
    margin-top: 20px;
}

.ladiui.radio.custom-ldp-radio1 {
    margin-top: 16px;
    margin-bottom: 8px;
}

.ladiui.member .member-img-left {
    margin-right: 16px;
}

.ladiui.member .member-img-left img {
    height: 32px;
    width: 32px;
}

.ladiui.member .member-control {
    margin: 8px 0;

}

.ladiui.member .member-control:first-child {
    margin-top: 0;
}

.ladiui.member .member-control:last-child {
    margin-bottom: 0;
}

.ladiui.modal-header.custom-data-backup {
    padding: 0px 0px 4px 0px;
}

.ladiui.modal-footer.custom-ldp-decentralization {
    padding: 54px 0px 0px 0px;
}

.ladiui.help-footer {
    margin-right: 8px;
}

.ladiui.modal-footer-link a {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}

.ladiui.list-user {
    margin-top: 32px;
}

.ladiui.container-list-user {
    margin: 8px 0px 16px 0px;
}

.ladiui.text-sub-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-title-custom {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.container-list-user-left {
    display: flex;
    flex-direction: column;
}

.ladiui.container-list-user,
.ladiui.container-list-user-right {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

/*----------------------------------------------------------------------------------------custom button popup----------------------------------------------------------------------------------------*/
.ladiui.btn-outline-primary.custom-popup {
    font-weight: 500;
    margin-right: 24px;
}

.ladiui.modal-header.custom-popupX {
    padding: 0px 0px 4px 0px;
}

.ladiui.text-sub-notification {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
}

.ladiui.text-sub-bold {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.modal-footer.custom-ldp-popupX {
    padding: 48px 0px 0px 0px;
}

/* --------------------------------------------------------------------------------------Access------------------------------------------------------------------------------ */
.ladiui.access.content-main-tool .search-group {
    width: 270px;
}

.ladiui.modal.key-access .modal-dialog {
    width: 963px;
}

.ladiui.modal.key-access .search-group {
    width: 270px;
}

.ladiui.modal.key-access .col-first {
    width: 40px;
}

.ladiui.modal.key-access .col-one {
    min-width: 80px;
    width: 80px;
}

.ladiui.modal.key-access .col-tow {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-three {
    min-width: 117px;
    width: 124px;
}

.ladiui.modal.key-access .col-four {
    min-width: 116px;
    width: 116px;
    text-align: center;
}

.ladiui.modal.key-access .col-five {
    min-width: 136px;
    width: 130px;
    text-align: center;
}

.ladiui.modal.key-access .col-six {
    min-width: 16px;
    width: 85px;
    text-align: center;
}

.ladiui.modal.key-access .col-seven {
    min-width: 107px;
    width: 107px;
}

.ladiui.modal.key-access .btn-group {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access .btn-group .dropdown-toggle {
    min-width: 64px;
    width: 64px;
}

.ladiui.modal.key-access table .btn-group .dropdown-menu {
    min-width: 175px;
    width: 175px;
}

.ladiui.modal.key-access .btn-group .dropdown-menu {
    min-width: 65px;
    width: 65px;
}

.ladiui.create-key .dropdown-toggle {
    min-width: 100px;
}

.ladiui.create-key .dropdown-toggle::after {
    content: none;
}

.ladiui.create-key .dropdown-menu {
    min-width: 145px;
    width: 145px;
}

.ladiui.confirm-2 .modal-footer {
    margin-top: 20px;
}

.ladiui.funnel-text {
    margin-left: 50px;
}

.ladiui.funnel-text label {
    margin-left: 246px;
}

.ladiui.funnel-text label:first-child {
    margin-left: 0;
}

.ladiui.funnel-wrapper {
    display: flex;
}

.ladiui.group-input {
    display: flex;
    align-items: center;
    border: 1px solid var(--secondary-color);
    width: 712px;
    border-radius: 2px;
}

.ladiui.group-input .form-control {
    border: 1px solid var(--main-white);
    padding-left: 8px;
}

.ladiui.space-between {
    width: 2px;
    height: 20px;
    background-color: var(--secondary-color);
}

.ladiui.wrapper-group .ladiui-btn-tag {
    width: 34px;
    height: 34px;
    padding: 6px;
    text-align: center;
    margin-right: 8px;
}

.ladiui.sortable-line {
    margin: 8px 0;
}

body {
    counter-reset: section;
}

.ladiui.sortable-line .ladiui-btn-tag::before {
    counter-increment: section;
    content: counter(section);
}

.ladiui.sortable-line:first-child {
    margin-top: 0;
}

.ladiui.sortable-line:last-child {
    margin-bottom: 0;
}

.ladiui.first.form-control {
    width: 552px;
}

.ladiui.group-input .icon-ldp-drag {
    cursor: grab;
    pointer-events: all;
    margin-right: 10px;
}

.ladiui.sortable-line .icon-ldp-delete {
    cursor: pointer;
    pointer-events: all;
    margin-left: 10px;
}

.ladiui.funnel .proviso {
    padding: 16px 0 0 0;
}

.ladiui.funnel td {
    padding-top: 10px;
    padding-bottom: 10px;
}

.ladiui.funnel .col-first {
    width: 16px;
}

.ladiui.funnel .col-one {
    width: 470px;
}

/*----------------------------------------------------------------------------------------dynamic-report----------------------------------------------------------------------------------------*/
.ladiui.dynamic-report-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
}

.ladiui.dynamic-report-type {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.dynamic-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-secondary)
}

.ladiui.dynamic-span {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-left: 8px;
}

.ladiui.dropdown-select-dynamic {
    margin-top: 10px;
}

.ladiui.btn-group.pull-right {
    margin-left: 24px;
}

.ladiui.dynamic-report-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 50px;
}

.ladiui.report-total-item {
    border: 1px solid var(--main-primary);
    border-radius: 10px;
    min-width: 188px;
    height: 106px;
}

.ladiui.report-total-item-lable {
    padding: 24px 0px 0px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
}

.ladiui.report-total-item-number {
    padding: 4px 0px 0px 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    color: var(--main-default);
}

.ladiui.dynamic-report-bg {
    border: 1px solid var(--main-line);
    margin-top: 50px;
    border-radius: 5px;
}

.ladiui.table.boder {
    margin-top: 0px;
}

.ladiui.table-vertical.header.item th {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.item td {
    padding: 16px 16px 0px 16px;
}

.ladiui.table-vertical.main.end td {
    padding: 16px 16px 25px 16px;
}

.ladiui.search-action.custom {
    margin: 0px 16px 16px 16px;
}

.ladiui.report-convert {
    display: grid;
    grid-template-columns: 8fr 4fr;
    grid-gap: 24px;
    margin-top: 24px;
}

.ladiui.report-convert-left {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-right {
    border-radius: 10px;
    border: 1px solid var(--secondary-color);
}

.ladiui.report-convert-title {
    text-align: center;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin: 16px 0px 16px 0px;
}

.ladiui.report-convert-right {
    text-align: center;
}

.ladiui.report-convert-right img {
    margin-top: 32px;
}

/* ----------------------------------------------------------------------------------conversion-tool--------------------------------------------------------------------------------------- */
.ladiui.conversion-tab {
    height: 80vh;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.ladiui.conversion-content-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--main-default);
    margin-bottom: 16px;
}

/*custom modal dynamic*/
.ladiui.modal .btn-group.custom-form-dynamic {
    width: 180px;
}

.ladiui.modal .btn-group .dropdown-toggle.custom-form-dynamic,
.ladiui.modal .btn-group .dropdown-menu.custom-form-dynamic {
    width: 180px;
}

.ladiui.item-form.sub1 {
    margin-left: 24px;
}

.ladiui.switch.custom-form-dynamic {
    margin: 0px;
}

.ladiui.item-form.custom-form-dynamic {
    margin-left: 42px;
}

.ladiui.title-text-form-lever2.custom-form-dynamic {
    margin-bottom: 16px;
}

.ladiui.item.flex-row.custom-form-dynamic {
    align-items: flex-start;
}

.ladiui.dropdown-menu.custom-dropdown-dynamic {
    width: 200px !important;
}

.ladiui.btn-group.col-2,
.ladiui.dropdown-toggle.col-2,
.ladiui.dropdown-menu.col-2 {
    width: 320px !important;
    margin-right: 16px;
}

.ladiui.itemss {
    justify-content: flex-start;
}

.ladiui.items-1 {
    width: 310px;
}

.ladiui.items-2 {
    position: relative;
    width: 310px;
    margin-left: 16px;
}

.ladiui.items-3 {
    width: 90px;
    margin-left: 16px;
}

.ladiui.items-4 {
    position: relative;
    width: 704px;
}

.ladiui.flex-row.custom-create-dynamic {
    margin-top: 4px;
}

.ladiui.modal-footer.custom-ldp-popupX.mod {
    padding-top: 36px;
}

.ladiui.infomation-link {
    margin-bottom: 19px;
    margin-left: 4px;
}

.ladiui.infomation-links {
    margin: 0px 0px 12px 4px;
}

.ladiui.dropdown-toggle.custom-main-tool.dynamic,
.ladiui.dropdown-menu.custom-dynamic {
    width: 320px !important;
    margin-left: 16px;
}

.ladiui.btn-group.custom-dynamic {
    width: 336px !important;
}

.ladiui.bg-form-custom.custom-dynamic {
    margin-top: 28px;
}

.ladiui.subtitle-2.custom-dynamic {
    margin-top: 8px;
    font-weight: 400;
    margin-left: 326px;
}

.ladiui.icon-add-input {
    position: absolute;
    top: 8px;
    right: 8px;
}

.ladiui.icon-add-input1 {
    position: absolute;
    top: 5px;
    right: 8px;
}

.ladiui.flex-row.custom-dynamic-s {
    text-align: center;
}

.ladiui.infomation-linkss {
    margin-left: 8px;
}

.ladiui.icon-search.custom {
    display: block;
    position: relative;
    left: 0px;
    margin: 18px 8px 2px 0px;
}

.ladiui.search-tag-fixed {
    align-items: baseline;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-mute)
}

.ladiui.inpud-search-tag-fixed {
    position: relative;
    bottom: 3px;
    width: 165px;
}

.ladiui.delete-right {
    margin-left: 16px;
}

.ladiui.dropdown-group-form {
    max-width: 744px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ladiui.dropdown-toggle.select-dropdown.col-3 {
    width: 280px !important;
}

.ladiui.btn-group.ctm {
    width: 196px !important;
}

.ladiui.btn-group.ctms {
    width: 280px !important;
    margin-left: 16px;
}

.ladiui.dropdown-menu.ctms {
    width: 280px !important;

}

.ladiui.btn-group.ctm.col2 {
    margin-left: 16px;
}

.ladiui.conversion.content-main {
    display: none;
}

.ladiui.conversion.main .tab-link {
    border-bottom: none;
    margin-left: 12px;
}

/* ---------------------------------------------------------------------------------------Tracking global --------------------------------------------------------------------------------------- */
.ladiui.nav-tracking {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.tracking-area .ladiui.nav-tracking .tab-link.active {
    border-bottom: 2px solid var(--main-primary);
}

.ladiui.tracking-area .ladiui.tab-content .tab-pane {
    margin-top: 0;
}

.ladiui.tracking-list .col-four {
    min-width: 200px;
    width: 200px;
}

.ladiui.tracking-list .col-first {
    min-width: 40px;
    width: 40px;
}

.ladiui.account-tracking img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

/* ------------------------------------------------------------------------------------------conversion-link-------------------------------------------------------------------------------------------- */
.ladiui.grid-link {
    width: 795px;
    height: 384px;
    display: grid;
    grid-template-columns: 263px 263px 263px;
    grid-template-rows: 136px 136px 136px 136px 136px 136px 136px 136px 136px 136px;
    overflow-y: auto;
}

.ladiui.link-box {
    cursor: pointer;
    width: 250px;
    height: 120px;
    border: 1px solid var(--main-line);
    border-radius: 2px;
}

.ladiui.link-box:hover {
    border: 1px solid var(--main-primary);
}

.ladiui.link-header {
    pointer-events: none;
    margin-bottom: 24px;
    padding: 16px 16px 0 16px;
}

.ladiui.link-header img {
    margin-right: 16px;
}

.ladiui.link-body {
    pointer-events: none;
    padding: 0px 16px 16px 16px;
    color: var(--main-secondary);
}

.ladiui.modal-header img {
    margin-right: 16px;
}

.ladiui.header-gmail {
    width: 36px;
    height: 24px;
}

.ladiui.header-ladisale {
    width: 32px;
    height: 32px;
}

.ladiui.flex.input .ladiui.form-group {
    width: 100%;
    margin: 24px 12px 0;
}

.ladiui.flex.input .ladiui.form-group:first-child {
    margin-left: 0;
}

.ladiui.flex.input .ladiui.form-group:last-child {
    margin-right: 0;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-toggle {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .btn-group .ladiui.smtp.dropdown-menu {
    min-width: 100px;
    width: 100px;
}

.ladiui.modal .smtp.flex .btn-group {
    min-width: 100px;
    width: 100px;
}

.ladiui.smtp-host {
    width: 264px;
}

.ladiui.smtp-port {
    width: 140px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-toggle {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .btn-group .ladiui.esms.dropdown-menu {
    min-width: 264px;
    width: 264px;
}

.ladiui.modal .esms.flex .btn-group {
    min-width: 264px;
    width: 264px;
}

.ladiui.form-group .btn.convert {
    margin-top: 27px;
}

.ladiui.ggform-url .form-group .form-control {
    width: 450px;
}

.ladiui.form-field {
    margin-top: 16px;
    width: 100%;
}

.ladiui.form-field .icon {
    margin: 0 16px;
}

.ladiui.form-field .icon:last-child {
    margin-right: 0;
}

.ladiui.form-field .form-control {
    width: 252px;
}

.ladiui.form-field .btn-group {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-toggle {
    min-width: 220px;
    width: 220px;
}

.ladiui.modal .form-field .btn-group .dropdown-menu {
    min-width: 220px;
    width: 220px;
}

.ladiui.form-field .size-checkbox {
    margin: 0 12px 0 16px;
}

.ladiui.type-account {
    align-items: center;
    text-align: center;
}

.ladiui.type-account img {
    margin: 0 8px 0 16px;
    height: 24px;
    width: 24px;
}

.ladiui.type-account .custom-img {
    height: 16px;
    width: auto;
}

/* ----------------------------------------------------------------------------------Conversion-configuration--------------------------------------------------------------------------------------------- */
.ladiui.config-box .about {
    text-align: center;
    width: 190px;
    margin-top: 8px;
}

.ladiui.config .search-group {
    min-width: 270px;
}

.ladiui.config .step {
    margin: 16px 0 32px;
}

.ladiui.config .step:last-child {
    margin: 40px 0 0;
}

.ladiui.grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 350px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group {
    padding-right: 20px;
    margin-right: -20px;
    max-height: 250px;
    overflow-y: auto;
}

.ladiui.setting-config .grid-linking-form {
    display: grid;
    align-items: baseline;
    grid-template-columns: 300px auto auto;
    padding: 16px 0;
}

.ladiui.linking-form-group::-webkit-scrollbar {
    width: 3px;
}

.ladiui.linking-form-group .grid-linking-form {
    border-bottom: 1px solid var(--main-line);
}

.ladiui.grid-linking-form .type-account {
    text-align: start;
}

.ladiui.grid-linking-form .type-account .account-tracking img {
    margin-left: 0;
    width: 16px;
    height: 16px;
}

.ladiui.grid-linking-form .select-button {
    text-align: end;
}

.ladiui.grid-linking-form .select-button .edit-icon {
    margin-right: 10px;
}

/*------------------------------------------------------------------------------------------template----------------------------------------------------------------------------------*/
.ladiui.content-main-tool.custom-template {
    margin-top: 0px;
}

.ladiui.content-main-tab {
    padding-bottom: 16px;
    margin-top: 24px;
    border-bottom: 1px solid var(--main-line);
}

.ladiui.content-main-tab-flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ladiui.content-main-tab-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.ladiui.content-main-item-hover {
    display: none;
    position: absolute;
    top: 20px;
    left: -72px;
    background-color: var(--main-white);
    z-index: 999;

}

.ladiui.content-main-grid {
    min-width: 824px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.ladiui.content-main-item.col4 {
    margin: 24px 24px 24px 24px;
}

.ladiui.content-main-item-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 8px;
}

.ladiui.content-main-item-subtext {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-default);
    margin-bottom: 8px;
    width: 250px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-main-item-hover {
    display: block;
}

.ladiui.content-main-tab-item.tow:hover .ladiui.content-main-item-hover.tow {
    display: block;
}

.ladiui.content-main-tab-item.three:hover .ladiui.content-main-item-hover.three {
    display: block;
}

.ladiui.content-main-tab-item.for:hover .ladiui.content-main-item-hover.for {
    display: block;
}

.ladiui.content-main-item-hover.tow {
    width: 265px;
}

.ladiui.content-main-tab-item.one:hover .ladiui.content-tab-text {
    color: var(--main-primary);
}

.ladiui.custom-width-tab {
    margin-left: 32px;
}

.ladiui.custom-width-tab-item {
    margin-left: 4px;
}

.ladiui.content-tab-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    pointer-events: none;
    color: var(--main-default);
}

.ladiui.content-main-tab-item.tab-link {
    padding-bottom: 0px;
}

.ladiui.content-main-tab-item.tab-link.active {
    border: 0px;
}

.ladiui.content-tab-icon {
    pointer-events: none;
}

.ladiui.tab-item-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-secondary);
    margin: 24px 0px 4px 0px;
}

.ladiui.tab-item-text-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-bottom: 16px;
}

.ladiui.custom-btn {
    padding: 6px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    border-radius: 2px;
    color: var(--main-black);
    background-color: var(--main-white);
    border: 1px solid var(--main-line);
    cursor: pointer;
}

.ladiui.custom-btn:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.custom-btn:active {
    color: var(--main-white);
    background-color: var(--main-primary);
}

.ladiui.custom-btn.active {
    color: var(--main-white);
    background-color: var(--main-primary);
    cursor: pointer;
}

.ladiui.group-item-main-sub {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ladiui.custom-left-group-button {
    margin-left: 8px;
}

.ladiui.template-grid {
    min-width: calc(100% / 3 - 20px);
    min-height: 290px;
    border-radius: 2px;
    margin-top: 32px;
    position: relative;
    border: 1px solid var(--main-line);
}

.ladiui.opacity-img {
    width: 100%;
    height: 100%;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    opacity: 0.09;
}

@media (max-width: 1371px) {
    .ladiui.template-grid {
        width: calc(100% / 2 - 20px);
        min-height: 290px;
        border-radius: 2px;
        margin-top: 32px;
        position: relative;
    }

    .ladiui.opacity-img {
        min-width: calc(100% / 2 - 20px);
        min-height: 250px;
    }

}

.ladiui.template-grid-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--main-default);
    margin-top: 16px;
}

.ladiui.template-grid-type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.ladiui.template-grid-type-hover {
    position: absolute;
    top: 8px;
    left: 8px;
    display: none;
}

.ladiui.template-grid-button {
    width: 100px;
    height: 36px;
    background-color: var(--main-primary);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-white);
}

.ladiui.group-button-template-grid {
    position: absolute;
    top: 107px;
    left: calc((100% - 216px) / 2);
    display: none;
}

.ladiui.template-grid:hover .ladiui.group-button-template-grid {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type-hover {
    display: block;
}

.ladiui.template-grid:hover .ladiui.template-grid-type {
    display: none;
}

.ladiui.template-grid:hover .ladiui.opacity-img {
    background-color: var(--main-white);
}

.ladiui.template-grid-button {
    cursor: pointer;

}

.ladiui.template-grid-button:hover {
    color: var(--main-white);
    background-color: var(--hover-primary);
}

.ladiui.content-main-item-subtext:hover {
    color: var(--main-primary);
}

.ladiui.template-btn-show-more {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--main-primary);
}

.ladiui.show-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    cursor: pointer;
}

.ladiui.template-btn-show-more {
    margin-bottom: 6px;
}

.ladiui.banner-ads-grid.showcase {
    margin-top: 0px;
}

.ladiui.modal-dialog.showcase {
    width: 824px;
    text-align: center;
    box-shadow: 0px 3px 15px rgba(169, 184, 200, 0.15);
    border-radius: 10px;
}

.ladiui.modal-footer.showcase {
    justify-content: center;
}

.ladiui.show-case-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
}

.ladiui.show-case-sub {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 31px 0px 48px 0px;
}

.tox-notifications-container {
    height: 0px !important;
    overflow: hidden !important;
}